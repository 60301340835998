import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { removeLocalStorage } from '../utils/localStorage';

const Header = ({ sidebar, setSidebar }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const signOut = async () => {
    dispatch({
      type: 'clear_all',
    });
    await removeLocalStorage('token');
    setTimeout(() => {
      navigate("/signin")
    }, 200);
  }

  return (
    <>
      <div className="h-20 bg-white hidden sm:flex justify-between items-center px-12 w-full">
        <div className="flex flex-1">
          <span className="text-xl font-semibold">สายด่วน Family :</span>
          <span className="text-3xl font-bold">1678</span>
        </div>
        <div className="flex flex-1 justify-center">
          <Link to="/">
            <div
              className="bg-center bg-contain bg-no-repeat h-10 w-40 cursor-pointer"
              style={{
                backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")`,
              }}
            ></div>
          </Link>
        </div>
        <div className="flex flex-1 justify-end gap-x-4">
          {/* <Link to="signin"> */}
          {
            userState?.username &&
            <span className="text-xl font-semibold">
              ชื่อผู้ใช้งาน : {userState?.userFullname}
            </span>
          }
          <span onClick={signOut} className="text-xl font-semibold cursor-pointer text-red-600">
            ออกจากระบบ
          </span>
          {/* </Link> */}
        </div>
      </div>
      <div className="h-12 gap-4 sm:gap-10 md:gap-14 lg:gap-20 hidden bg-black sm:flex justify-center items-center px-12">
        <div className="flex ">
          <Link to="/job">
            <span
              className={`text-xl ${location.pathname === "/job"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              ใบงานเข้าใหม่
            </span>
          </Link>
        </div>
        <div className="flex">
          <Link to="/schedule">
            <span
              className={`text-xl ${location.pathname === "/schedule"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              ตารางงานของฉัน
            </span>
          </Link>
        </div>
        <div className="flex">
          <Link to="/progress">
            <span
              className={`text-xl ${location.pathname === "/progress"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              งานที่กำลังทำ
            </span>
          </Link>
        </div>
        <div className="flex">
          <Link to="/history">
            <span
              className={`text-xl ${location.pathname === "/history"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              งานที่ทำเสร็จแล้ว
            </span>
          </Link>
        </div>
      </div>
      <div className="bg-white flex flex-col sm:hidden px-4 py-4">
        <div className="flex justify-between items-center mt-4">
          <div onClick={() => setSidebar(!sidebar)}>
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              className="text-black"
            />
          </div>
          <div
            className="bg-center bg-contain bg-no-repeat self-center h-12 w-40"
            style={{
              backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")`,
            }}
          ></div>
          {/* <Link to="signin"> */}
          <div onClick={signOut} className="flex cursor-pointer">

            <span className="text-xl font-semibold text-red-600">ออกจากระบบ</span>
          </div>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default Header;
