import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCalendarDay,
  faUser,
  faPlayCircle,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import CSwlAlert from "../../utils/alert";
import ApiTransaction from "../../api/ApiTransaction";
import _ from 'lodash';
import { ConvertDate, getPeriod } from "../../utils";

const ProgressList = () => {
  const navigate = useNavigate();
  const [search, setsearch] = useState('');
  const [orderHistory, setorderHistory] = useState([]);


  const [modal, setModal] = useState(false);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getHistory();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getHistory = async () => {
    try {
      const response = await ApiTransaction.getTransaction({ status: "CP,WR" });
      if (response.status == 200) {
        const { data } = response.data;
        const groupItems = _(data)
          .groupBy('maRef')
          .map((items, key) => ({ main: key, items: items }))
          .value();
        setorderHistory(groupItems);
        console.log('groupItems::', groupItems)
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        navigate(-1)
      });
    }

  }

  const startProject = async (item) => {
    const main = item.main;
    const confirm = await CSwlAlert.SwalConfirm(`คุณต้องการจะเริ่มงาน ${main} \n ใช่หรือไม่`);
    if (confirm.isConfirmed) {
      CSwlAlert.SwalLoad();
      try {
        const response = await ApiTransaction.update({
          maRef: main,
          maStatus: "FS"
        });
        if (response.status == 200) {
          await CSwlAlert.SwalClose();
          navigate(`/progress/${main}`);
        }
      } catch (error) {
        const { data } = error.response;
        CSwlAlert.SwalErr({ description: data.message })
      }
    }
  }

  return (
    <div>
      <div className="text-center py-3 text-xl bg-yellow">ใบงานที่ทำเสร็จแล้ว</div>
      <div className="container max-w-3xl mx-auto py-8">
        <input

          placeholder="ค้นหา"
          className="form-control text-xl w-full mb-5 border-[#ababab]"
          value={search}
          onChange={(e) => {
            setsearch(e.target.value)
          }}
        />
        {orderHistory.filter(x =>
          x.main.includes(search)
          || (x.items.length > 0 && x.items[0].name?.includes(search))
          || (x.items.length > 0 && x.items[0].addressUser?.includes(search))
        ).map((item) => {

          const maItemgroup = _(item.items)
            .groupBy('itemRef')
            .map((items, key) => ({
              ...items[0]
            }))
            .value();
          return (
            <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
              <div>
                <div className="flex justify-between py-5 pl-5 pr-3 sm:px-12">
                  <div className="text-lg sm:text-2xl">
                    เลขที่แจ้งซ่อม : {item.main}
                  </div>
                  <div className="flex items-center gap-x-1 px-3 sm:py-1 bg-yellow rounded-full">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-[#6A8680] text-xl sm:text-2xl"
                    />
                    <div className="sm:text-xl  font-semibold">
                      {item.items[0].zone}
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-5 pt-2 pb-5 sm:px-12">
                <div className="text-2xl sm:text-3xl mb-3 font-semibold">
                  รายการแจ้งซ่อม
                </div>
                <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
                  {maItemgroup.map((work, index) => {
                    return (
                      <div className="py-3 sm:py-6 gap-y-1 flex flex-col">
                        <div className="text-xl sm:text-2xl">
                          รายการที่ {index + 1}
                        </div>
                        <div className="flex gap-x-5">
                          {/* <img src={item.images?.length > 0 && process.env.REACT_APP_IMAGE + item.images[0].pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" /> */}
                          <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg h-fit">
                            {work.catalogName}
                          </div>
                          <div className="text-lg sm:text-xl">
                            {work.subCatalogName}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="py-6 px-5 sm:px-12">
                <div className="flex items-center gap-x-3">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                    {`${ConvertDate(item.items[0].dateTime, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(item.items[0].period)})`}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-2">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">สถานที่ :</span> {item.items[0].addressUser}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-2">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">ผู้ใช้บริการ :</span>{" "}
                    {item.items[0].name}, Tel : {item.items[0].phone}
                  </div>
                </div>
              </div>
              <div className="flex justify-between py-3 px-5 sm:px-12">
                <div className="flex sm:gap-x-3 justify-center items-center text-xl py-2">
                  <span className="text-2xl mr-2 sm:mr-0">คะแนน</span>
                  {item.items[0].rating != 0 ?
                    [1, 2, 3, 4].map((num, index) => {
                      return num <= item.items[0].rating ? (
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-xl sm:text-2xl text-yellow"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-xl sm:text-2xl text-gray-light opacity-60"
                        />
                      );
                    }) :
                    <div className="text-xl text-gray py-2">
                      - - -
                    </div>}
                </div>
                <Link to={`/job-detail/${item.main}`}>
                  <div className="btn-black-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                    ดูรายละเอียดการแจ้งซ่อม
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressList;
