import { useState } from "react"
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/home";
import Layout from './components/Layout';
import SignIn from "./pages/signin";
import Job from "./pages/job";
import JobDetail from "./pages/job/JobDetail";
import Schedule from "./pages/schedule";
import Progress from "./pages/progress";
import PostPone from "./pages/progress/PostPone";
import History from "./pages/history";
import CheckJob from "./pages/checkjob";
import SummaryJob from "./pages/summary-job";
import ProgressList from "./pages/progress/list";
function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Job />} />
          {/* <Route path="job" element={<Job />} />
          <Route path="jobTimeline" element={<JobTimeline />} />
          <Route path="check" element={<Check />} /> */}

          {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
          <Route path="*" element={<Job />} />
          <Route index path="/job" element={<Job />} />
          <Route path="/job-detail/:ref" element={<JobDetail />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/progress" element={<ProgressList />} />
          <Route path="/progress/:ref" element={<Progress />} />
          <Route path="/postpone/:ref" element={<PostPone />} />
          <Route path="/history" element={<History />} />
          <Route path="/check-job/:ref" element={<CheckJob />} />
          <Route path="/summary-job" element={<SummaryJob />} />
        </Route>
        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
