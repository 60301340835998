import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTools,
  faBell,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const NavbarMobile = () => {
  return (
    <div className="flex sm:hidden sticky bottom-0 bg-black text-white  text-2xl divide-x-2 divide-gray-dark">
      <Link to={`/job`} className="flex-grow flex-shrink-0 w-4/12">
        <div className="flex justify-center items-center gap-x-2 py-3">
          <FontAwesomeIcon
            icon={faBell}
            className="text-gray text-xl sm:text-2xl ml-1 sm:ml-2"
          />
          งานเข้าใหม่
        </div>
      </Link>
      <Link to={`/schedule`} className="flex-grow flex-shrink-0 w-4/12">
        <div className="flex justify-center items-center gap-x-2 py-3">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="text-gray text-xl sm:text-2xl ml-1 sm:ml-2"
          />
          ตารางงาน
        </div>
      </Link>
      <Link to={`/progress`} className="flex-grow flex-shrink-0 w-4/12">
        <div className="flex justify-center items-center gap-x-2 py-3">
          <FontAwesomeIcon
            icon={faTools}
            className="text-gray text-xl sm:text-2xl ml-1 sm:ml-2"
          />
          งานที่กำลังทำ
        </div>
      </Link>
    </div>
  );
};

export default NavbarMobile;
