import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiCatalog extends Component {
    static getCatalog = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: "Catalog",
            method: "get",
        });
        return result;
    };

    static getSubCatalog = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: "Catalog/Sub",
            method: "get",
        });
        return result;
    };
}

export default ApiCatalog;
