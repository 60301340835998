import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCalendarDay,
  faUser,
  faTrashAlt,
  faCirclePlus,
  faCircleChevronRight,
  faTimesCircle,
  faCircleChevronLeft,
  faTrash,
  faUndo,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import CSwlAlert from "../../utils/alert";
import ApiTransaction from "../../api/ApiTransaction";
import _ from 'lodash';
import { ConvertDate, getPeriod, getWeeks } from "../../utils";
import ModalReview from "../../components/ModalReview";
import ApiCatalog from "../../api/ApiCatalog";
import { Switch } from '@headlessui/react'
import SignatureCanvas from 'react-signature-canvas'
import ApiConfig from "../../api/ApiConfig";
import moment from 'moment';

const CheckJob = () => {
  const signatureRef = useRef(null);
  const [listRepair, setListRepair] = useState([]);
  let { ref } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState('');
  const [catalogs, setCatalogs] = useState([]);
  const [subcatalogs, setSubCatalogs] = useState([]);
  const [step, setstep] = useState(1);
  const [summaryList, setsummaryList] = useState([]);
  const [slotCount, setslotCount] = useState(1);
  const [signatureState, setsignatureState] = useState(null);
  const [dateNow, setdateNow] = useState(moment());
  const [dateList, setdateList] = useState([]);
  const [holidayList, setholidayList] = useState([]);
  const [datePickList, setDatePickList] = useState([]);
  // const [periodSelected, setperiodSelected] = useState();
  const [config, setConfig] = useState("");
  const [orderHistory, setorderHistory] = useState([]);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    const promise2 = getCatalogs();
    const promise3 = getSubCatalogs();
    const promise4 = getConfigs();
    Promise.all([promise1, promise2, promise3, promise4]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  useEffect(() => {
    const weeks = getWeeks(dateNow.toDate());
    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
    setdateList(weeks);
  }, []);

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRef(ref);
      if (response.status == 200) {
        const { data } = response.data;
        const groupItems = _(data)
          .groupBy('maRef')
          .map((items, key) => ({
            main: key,
            date: items[0].dateTime,
            period: items[0].period,
            zone: items[0].zone,
            status: items[0].status,
            name: items[0].name,
            addressUser: items[0].addressUser,
            phone: items[0].phone,
            items: items,
          }))
          .value();
       
      
        if (groupItems.filter(x => x.status == "FS").length > 0) {
          groupItems[0].items.map(x => {
            delete x.images
          });
          setListRepair(groupItems[0].items);
          setOrder(groupItems[0]);
        } else {
          await CSwlAlert.SwalErr({ description: "ไม่พบรายการนี้" });
          navigate('/schedule')
        }

      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        navigate('/schedule')
      });
    }

  }

  const [addRepair, setAddRepair] = useState([]);
  const [modal, setModal] = useState(false);

  const [itemRemove, setItemRemove] = useState({});

  const addList = () => {
    setAddRepair([
      ...addRepair,
      {
        enabled: "",
        repair: "",
        jobType: "",
        detail: "",
        picture: [],
        video: [],
      },
    ]);
  };

  const uploadFile = async (e, index) => {
    if (e.target.files[0]) {
      let arr = [...listRepair];
      if (!arr[index].picture) {
        arr[index].picture = [];
      }
      arr[index].picture.push(e.target.files[0]);
      setListRepair([...arr]);
    }
  }

  const uploadFileNewItems = async (e, index) => {
    if (e.target.files[0]) {
      let arr = [...addRepair];
      if (!arr[index].picture) {
        arr[index].picture = [];
      }
      arr[index].picture.push(e.target.files[0]);
      setAddRepair([...arr]);
    }
  }


  const getCatalogs = async () => {
    const response = await ApiCatalog.getCatalog();
    if (response.status == 200) {
      const { data } = response.data;
      setCatalogs(data);
    }
  }
  const getSubCatalogs = async () => {
    const response = await ApiCatalog.getSubCatalog();
    if (response.status == 200) {
      const { data } = response.data;
      console.log(data)
      setSubCatalogs(data);
    }
  }

  const getConfigs = async () => {
    const response = await ApiConfig.getConfigByCode('busy_date');
    if (response.status == 200) {
      const { data } = response.data;
      console.log('config', data);
      setConfig(data);
    }
  }

  const getHoliday = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiConfig.getHoliday({ dateFrom, dateTo });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      setholidayList(data);
    }
  }

  const getTransactionWeeks = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiTransaction.getTaskWeeks({ dateFrom, dateTo, status: "" });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      const groupItems = _(data)
        .groupBy('maRef')
        .map((items, key) => ({
          main: key,
          date: items[0].dateTime,
          period: items[0].period,
          zone: items[0].zone,
          status: items[0].status,
          items: items,
        }))
        .value();
      console.log('groupItems', groupItems);
      setorderHistory(groupItems);
    }
  }

  const checkDate = (date, period) => {
    if (period == 3) {
      if (slotCount > 1) {
        return true;
      }
    }
    const timeNow = moment().format('HH');
    if (period == 1 && timeNow >= 12 && moment(date).format("DD/MM/YYYY") == moment().add('days', 1).format("DD/MM/YYYY")) {
      return true;
    }
    const nowDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const pickDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const listHoliday = holidayList.map(x => new Date(x.holidaydate).toDateString())
    if (pickDate <= nowDate) {
      return true;
    }
    if (config?.value && config?.value.split(',').includes(ConvertDate(date, 'ddd'))) {
      return true;
    } else if (listHoliday.includes(date.toDateString())) {
      return true;
    }
    else if (orderHistory.filter(x => x.period == period).map(x => new Date(x.date).toDateString()).includes(date.toDateString())) {
      return true;
    }
    else {
      return false;
    }
  }

  const doneTask = async (e) => {
    e.preventDefault();
    const listRepairNew = [...listRepair];
    if (addRepair.length > 0) {
      addRepair.map((item, index) => {
        item.catalogName = catalogs.find(x => x.id == item.repair).catalogname;
        item.subCatalogName = subcatalogs.find(x => x.id == item.jobType)?.subcatalogName
        listRepairNew.push(item)
      })
    }
    const successItems = listRepairNew.filter(x => x.enabled == true).length;
    if (successItems == 0) {
      CSwlAlert.SwalErr({ title: "", description: "ไม่มีรายการที่ปิดงาน กรุณาเลือกอย่างน้อย 1 รายการ" })
    }
    else {
      let isPicture = true;
      listRepairNew.filter(x => x.enabled == true).map((item, index) => {
        if (!item.picture) {
          isPicture = false;
        }
      })
      if (isPicture == false) {
        CSwlAlert.SwalErr({ title: "", description: "รายการที่เสร็จแล้ว กรุณาอัพโหลดรูปภาพอย่างน้อย 1 ภาพ" })
      } else {
        setstep(2);
        setsummaryList(listRepairNew);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }

    }
  }

  const doneTaskCustomer = async (e) => {
    e.preventDefault();
    const successItems = summaryList.filter(x => x.enabled == true);
    const failedItems = summaryList.filter(x => x.enabled == false || !x.enabled);
    if (signatureRef.current.isEmpty()) {
      CSwlAlert.SwalErr({ title: "", description: "กรุณาเซ็น เพื่อตรวจรับงาน" })
    } else {
      if (failedItems.length > 0) {

        const confirmed = await CSwlAlert.SwalConfirm("มีรายการยังไม่เสร็จต้องทำการนัดใหม่ ต้องการสร้างใบงานใหม่ใช่หรือไม่");
        if (confirmed.isConfirmed) {
          const slots = _.orderBy(failedItems, 'slot', ['desc']);
          setslotCount(slots[0].slot)
          setstep(3);
          window.scrollTo(0, 0);
          setsignatureState(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'));
          //const createNewTaskResult = await createNewTask(failedItems);
          //console.log(updateItemResult)
        }
      } else {
        CSwlAlert.SwalLoad();
        const formData = new FormData();
        const pictures = [];
        await Promise.all(successItems.map((item, index) => {
          if (item?.picture) {
            item?.picture.forEach(element => {
              element.name_ref = `.ref-${index}`;
              pictures.push(element);
            });
            item.imageCount = item?.picture?.length
          }
        }));
        formData.append('transactionItem', JSON.stringify(successItems));
        await Promise.all(pictures.map((item, index) => {
          formData.append('images', item);
        }))
        try {
          const result = await ApiTransaction.updateVerify(formData, ref);
          if (result.status == 200) {
            const { tranRef } = result.data;
            CSwlAlert.SwalClose();
            const model = {
              maRef: ref,
              signature: signatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
            }
            const result2 = await ApiTransaction.sendSignature(model);
            if (result2.status == 200) {
              CSwlAlert.SwalClose();
              navigate('/history');
            }
          }
        } catch (error) {
          console.log(error);
          const { data } = error.response;
          CSwlAlert.SwalErr({ description: data.message })
        }
      }
    }
  }




  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  const deleteItem = async (item) => {
    if (listRepair.length == 1) {
      const confirm = await CSwlAlert.SwalConfirm(`เนื่องจากมีรายการเดียว การยกเลิกนี้จะมีผลทั้งใบงานจะไม่สามารถย้อนกลับได้ ต้องการลบใช่หรือไม่`, true);
      if (confirm.isConfirmed) {
        const result = await ApiTransaction.deleteMain({
          maReason: confirm.value,
          maRef: ref
        });
        if (result.status == 200) {
          navigate('progress');
        }
      }
    } else {
      const confirm = await CSwlAlert.SwalConfirm(`การยกเลิกรายการนี้จะไม่สามารถย้อนกลับได้ ต้องการลบใช่หรือไม่`, true);
      if (confirm.isConfirmed) {
        const result = await ApiTransaction.deleteItem({
          maReason: confirm.value,
          maRef: item.itemRef
        });
        if (result.status == 200) {
          getTransaction();
        }
      }
    }

  }

  const cancelRequest = async () => {
    const confirm = await CSwlAlert.SwalConfirm(`การยกเลิกนี้จะมีผลทั้งใบงานจะไม่สามารถย้อนกลับได้ ต้องการลบใช่หรือไม่`, true);
    if (confirm.isConfirmed) {
      const result = await ApiTransaction.deleteMain({
        maReason: confirm.value,
        maRef: ref
      });
      if (result.status == 200) {
        navigate('/progress');
      }
    }
  }

  const confirmTask = async (e) => {
    const successItems = summaryList.filter(x => x.enabled == true);
    const failedItems = summaryList.filter(x => x.enabled == false || !x.enabled);
    CSwlAlert.SwalLoad();
    e.preventDefault();
    if (!datePickList.length > 0) {
      CSwlAlert.SwalErr({ description: "กรุณาระบุวันที่ทำการนัดหมาย" });
      return false;
    }
    const formData = new FormData();
    const pictures = [];
    await Promise.all(failedItems.map((item, index) => {
      if (item?.picture) {
        item.picture.forEach(element => {
          element.name_ref = `.ref-${index}`;
          pictures.push(element);
        });
      }
      item.slot = slotCount;
      item.appointmentDate = datePickList[0].date;
      item.period = datePickList[0].period;
      item.imageCount = item?.picture?.length ?? 0
      item.appointmentLists = datePickList
      // formDataItem.append('repair', item.repair);
      // formData.append('req', formDataItem);
    }))
    formData.append('transactionItem', JSON.stringify(failedItems));
    await Promise.all(pictures.map((item, index) => {
      formData.append('images', item);
    }))
    try {
      const result = await ApiTransaction.createNewTask(formData, ref);
      if (result.status == 200) {
        const { tranRef } = result.data;
        CSwlAlert.SwalClose();
        //#region update signature
        CSwlAlert.SwalLoad();
        const formData = new FormData();
        const pictures = [];
        await Promise.all(successItems.map((item, index) => {
          if (item?.picture) {
            item?.picture.forEach(element => {
              element.name_ref = `.ref-${index}`;
              pictures.push(element);
            });
            item.imageCount = item?.picture?.length
          }
        }));
        formData.append('transactionItem', JSON.stringify(successItems));
        await Promise.all(pictures.map((item, index) => {
          formData.append('images', item);
        }))
        const result2 = await ApiTransaction.updateVerify(formData, ref);
        if (result2.status == 200) {
          const { tranRef } = result2.data;
          CSwlAlert.SwalClose();
          const model = {
            maRef: ref,
            signature: signatureState,
          }
          const result3 = await ApiTransaction.sendSignature(model);
          if (result3.status == 200) {
            navigate('/history')
          }
        }
        //#endregion
      }
    } catch (error) {
      console.log(error)
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  const checkSlotAvaiable = (date, period) => {
    const listHoliday = holidayList.map(x => new Date(x.holidaydate).toDateString())
    let dateList = [];
    let dateListItem = [];
    if (period == 1) {
      let daysOfslot = (slotCount / 2);
      for (let i = 0; i < daysOfslot; i++) {
        let dateAdd = moment(date).add(i, 'days');
        for (let j = 1; j < 3; j++) {
          if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
            dateList.push(false)
          } else {
            if (listHoliday.includes(dateAdd.toDate().toDateString())) {
              dateList.push(false)
            } else {
              if (listHoliday.includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                if (orderHistory.filter(x => x.period == j).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                  dateList.push(false)
                } else {
                  dateListItem.push({
                    date: dateAdd,
                    period: j
                  });
                  dateList.push(true)
                }
              }
            }

          }
        }

      }
    }
    else if (period == 2) {
      let daysOfslot = (slotCount / 2) + 1;
      for (let i = 0; i < daysOfslot; i++) {
        let dateAdd = moment(date).add(i, 'days');
        if (i == 0) {
          if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
            dateList.push(false)
          } else {
            if (listHoliday.includes(dateAdd.toDate().toDateString())) {
              dateList.push(false)
            } else {
              if (orderHistory.filter(x => x.period == 2).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                dateListItem.push({
                  date: dateAdd,
                  period: 2
                });
                dateList.push(true)
              }
            }

          }
        }
        else if (i == (daysOfslot - 1)) {
          if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
            dateList.push(false)
          } else {
            if (listHoliday.includes(dateAdd.toDate().toDateString())) {
              dateList.push(false)
            } else {
              if (orderHistory.filter(x => x.period == 1).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                dateListItem.push({
                  date: dateAdd,
                  period: 1
                });
                dateList.push(true)
              }
            }

          }
        }
        else {
          for (let j = 1; j < 3; j++) {
            if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
              dateList.push(false)
            } else {
              if (listHoliday.includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                if (orderHistory.filter(x => x.period == j).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                  dateList.push(false)
                } else {
                  dateListItem.push({
                    date: dateAdd,
                    period: j
                  });
                  dateList.push(true)
                }
              }

            }
          }
        }

      }
    }
    if (slotCount !== dateList.filter(x => x == true).length) {
      CSwlAlert.SwalErr({ description: `กรุณาเลือกวันที่ว่างติดต่อกัน ${slotCount} Slot` })
    } else {
      setDatePickList(dateListItem);
    }
  }


  return (
    <div>
      <div className="bg-yellow text-xl py-2 text-center">
        ตรวจรับงาน
      </div>
      {step == 1 &&
        <form onSubmit={doneTask}>
          <div className="container max-w-3xl mx-auto py-8">
            <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
              <div>
                <div className="flex justify-between py-5 pl-5 pr-3 sm:px-12">
                  <div className="text-lg sm:text-2xl">
                    เลขที่แจ้งซ่อม : {order.main}
                  </div>
                  <div className="flex items-center gap-x-1 px-3 sm:py-1 bg-yellow rounded-full">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-[#6A8680] text-xl sm:text-2xl"
                    />
                    <div className="sm:text-xl  font-semibold">
                      {order.zone}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 sm:px-12">
                <div className="flex items-center gap-x-3">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                    {`${ConvertDate(order.date, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.period)})`}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-2">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">สถานที่ :</span> {order.addressUser}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-2">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">ผู้ใช้บริการ :</span> {order.name}, Tel : {order.phone}
                  </div>
                </div>
                <div className="flex justify-end">
                  {/* <Link to={`/repair-cancel/${ref}`}> */}
                  <button type="button" onClick={cancelRequest} className="btn-gray-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                    ขอยกเลิกบริการ
                  </button>
                  {/* </Link> */}
                </div>
              </div>
              {order && listRepair.map((item, index) => {
                return (
                  <div className="px-5 pt-2 pb-5 sm:px-12">
                    {index === 0 ? (
                      <div className="text-2xl sm:text-3xl mb-3 font-semibold">
                        รายการแจ้งซ่อม
                      </div>
                    ) : (
                      <div className="mt-3"></div>
                    )}
                    <div>
                      <div className="flex items-center">
                        <div className="flex-grow rounded-[25px] pl-4 pr-5 sm:pl-[45px] sm:pr-[25px] border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
                          <div className="py-3 sm:py-6">
                            <div className="flex justify-between w-full">
                              <div className="flex-1 flex gap-x-2 items-center">
                                <Switch
                                  checked={item.enabled}
                                  onChange={(value) => {
                                    let arr = [...listRepair];
                                    arr[index].enabled = value;
                                    setListRepair([...arr]);
                                  }}
                                  className={`${item.enabled ? 'bg-blue' : 'bg-gray-light'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">ซ่อมเรียบร้อย</span>
                                  <span
                                    className={`${item.enabled ? 'translate-x-6' : 'translate-x-1'
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                                <div className="text-xl sm:text-2xl">
                                  รายการที่ {index + 1}
                                </div>
                              </div>
                              <div onClick={() => {
                                deleteItem(item);
                              }} className="p-1 cursor-pointer">
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-xl sm:text-2xl text-red-600"
                                />
                              </div>
                            </div>
                            <div className="flex gap-x-5 mt-3">
                              <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg">
                                {item.catalogName}
                              </div>
                              <div className="text-lg sm:text-xl">
                                {item.subCatalogName}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <FontAwesomeIcon
                     icon={faTrashAlt}
                     className="text-[#f06363] text-xl sm:text-2xl pl-6"
                     onClick={() => {
                       setItemRemove({
                         index: index + 1,
                         item: item,
                       });
                       setModal(true);
                     }}
                   /> */}
                      </div>
                      <div className="flex flex-col mt-9">
                        <span className="text-xl">รายละเอียด</span>
                        <input

                          placeholder="กรุณากรอกรายละเอียด"
                          className="form-control text-xl"
                          value={item.detail}
                          onChange={(e) => {
                            let arr = [...listRepair];
                            arr[index].detail = e.target.value;
                            setListRepair([...arr]);
                          }}
                        />
                      </div>
                      <div className="flex flex-col mt-3">
                        <span className="text-xl">ภาพประกอบ/วิดีโอ</span>
                        <div className="flex items-center gap-x-3 flex-wrap">
                          {item.picture?.length > 0 &&
                            item.picture.map((pic, index) => {
                              return (
                                <div key={`picture-${index}`} className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[152px]">
                                  <img
                                    src={URL.createObjectURL(pic)}
                                    alt={`pic-${index}`}
                                    className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                                  />
                                </div>
                              );
                            })}
                          <div className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3">
                            <label
                              htmlFor={"dropzone-file-" + index}
                              className="flex flex-col justify-center items-center w-full p-5 bg-gray-50 rounded-lg border-2 border-gray-light border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                              <div className="flex flex-col justify-center items-center">
                                <svg
                                  aria-hidden="true"
                                  className="mb-3 w-10 h-10 text-gray-light"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  ></path>
                                </svg>
                                <p className="mb-2 text-sm text-gray dark:text-gray">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>
                                </p>
                                <p className="mb-2 text-sm text-gray dark:text-gray">
                                  or drag and drop
                                </p>
                              </div>
                              <input
                                id={"dropzone-file-" + index}
                                type="file"
                                className="hidden"
                                name={"dropzone-file-" + index}
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                  uploadFile(e, index);
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {addRepair.map((item, index) => {
                return (
                  <section key={`${item.repair}-${index}`}>
                    <div
                      className="bg-white rounded-lg mb-9 mx-3 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]"
                      key={`${item.repair}-${index}`}
                    >
                      <div className="px-5 sm:px-10 py-8">
                        <div className="flex items-baseline gap-x-2">
                          <div className="flex flex-1">
                            <div className="flex items-center gap-x-2">
                              <Switch
                                checked={item.enabled}
                                onChange={(value) => {
                                  let arr = [...addRepair];
                                  arr[index].enabled = value;
                                  setAddRepair([...arr]);
                                }}
                                className={`${item.enabled ? 'bg-blue' : 'bg-gray-light'
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                              >
                                <span className="sr-only">ซ่อมเรียบร้อย</span>
                                <span
                                  className={`${item.enabled ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                              </Switch>
                              <div className="text-2xl sm:text-3xl mb-2 sm:mb-0 mr-3">
                                รายการที่ {index + listRepair.length + 1}
                              </div>
                            </div>

                            <select
                              className="form-control text-xl flex-grow"
                              value={item.repair}
                              onChange={(e) => {
                                let arr = [...addRepair];
                                arr[index].repair = e.target.value;
                                arr[index].jobType = "";
                                setAddRepair([...arr]);
                              }}
                              required
                              placeholder="กรุณาเลือกประเภทงาน"
                            >
                              <option value="">กรุณาเลือกประเภทงาน</option>
                              {catalogs.map((item, index) =>
                                <option key={"cata-" + item.id} value={item.id}>{item.catalogname}</option>
                              )}
                            </select>
                          </div>
                          <div onClick={() => {
                            let arr = [...addRepair];
                            arr.splice(index, 1);
                            setAddRepair([...arr]);
                          }} className="p-1 cursor-pointer">
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="text-xl sm:text-2xl text-red-600"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mt-9">
                          <span className="text-xl required">ประเภทงาน</span>
                          <select
                            className="form-control text-xl"
                            value={item.jobType}
                            onChange={(e) => {
                              let arr = [...addRepair];
                              arr[index].jobType = e.target.value;
                              arr[index].is_urgent = subcatalogs.find(x => x.id == e.target.value).isUrgent;
                              setAddRepair([...arr]);
                            }}
                            required
                            placeholder="กรุณาเลือกประเภทงาน"
                          >
                            <option value="">กรุณาเลือกประเภทงาน</option>
                            {subcatalogs.filter(x => x.catalogId == item.repair).map((item, index) =>
                              <option key={"subcat-" + item.id} value={item.id}>{item.subcatalogName}</option>
                            )}
                          </select>
                        </div>
                        <div className="flex flex-col mt-9">
                          <span className="text-xl">รายละเอียด</span>
                          <input
                            placeholder="กรุณากรอกรายละเอียด"
                            className="form-control text-xl"
                            value={item.detail}
                            onChange={(e) => {
                              let arr = [...addRepair];
                              arr[index].detail = e.target.value;
                              setAddRepair([...arr]);
                            }}
                          />
                        </div>
                        <div className="flex flex-col mt-9">
                          <span className="text-xl">ภาพประกอบ</span>
                          <div className="flex items-center gap-x-3 flex-wrap">
                            {item.picture.length > 0 &&
                              item.picture.map((pic, index) => {
                                return (
                                  <div key={`picture-${index}`} className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[152px]">
                                    <img
                                      src={URL.createObjectURL(pic)}
                                      alt={`pic-${index}`}
                                      className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                                    />
                                  </div>
                                );
                              })}
                            <div className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3">
                              <label
                                htmlFor={"dropzone-file-add" + index}
                                className="flex flex-col justify-center items-center w-full p-5 bg-gray-50 rounded-lg border-2 border-gray-light border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                              >
                                <div className="flex flex-col justify-center items-center">
                                  <svg
                                    aria-hidden="true"
                                    className="mb-3 w-10 h-10 text-gray-light"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    ></path>
                                  </svg>
                                  <p className="mb-2 text-sm text-gray dark:text-gray">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>
                                  </p>
                                  <p className="mb-2 text-sm text-gray dark:text-gray">
                                    or drag and drop
                                  </p>
                                </div>
                                <input
                                  id={"dropzone-file-add" + index}
                                  type="file"
                                  className="hidden"
                                  name={"dropzone-file-add" + index}
                                  accept="image/png, image/jpeg"
                                  onChange={(e) => {
                                    uploadFileNewItems(e, index);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex flex-col mt-9 px-5 sm:px-10 py-8">
                   <span className="text-xl">วิดีโอประกอบ</span>
                   <div className="flex items-center gap-x-3 overflow-x-auto">
                     {item.video.length > 0 &&
                       item.video.map((pic, index) => {
                         return (
                           <div key={`video-${index}`} className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[150px] sm:h-[152px]">
                             <img
                               src=""
                               alt={`vdo-${index}`}
                               className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                             />
                           </div>
                         );
                       })}
                     <div className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3">
                       <label
                         htmlFor="dropzone-file"
                         className="flex flex-col justify-center items-center w-full p-5 bg-gray-50 rounded-lg border-2 border-gray-light border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                       >
                         <div className="flex flex-col justify-center items-center">
                           <svg
                             aria-hidden="true"
                             className="mb-3 w-10 h-10 text-gray-light"
                             fill="none"
                             stroke="currentColor"
                             viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg"
                           >
                             <path
                               strokeLinecap="round"
                               strokeLinejoin="round"
                               strokeWidth="2"
                               d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                             ></path>
                           </svg>
                           <p className="mb-2 text-sm text-gray dark:text-gray">
                             <span className="font-semibold">
                               Click to upload
                             </span>
                           </p>
                           <p className="mb-2 text-sm text-gray dark:text-gray">
                             or drag and drop
                           </p>
                         </div>
                         <input
                           id="dropzone-file"
                           type="file"
                           className="hidden"
                           onChange={(e) => {
                             let arr = [...listRepair];
                             arr[index].video.push(e.target.files);
                             setListRepair([...arr]);
                           }}
                         />
                       </label>
                     </div>
                   </div>
                 </div> */}
                    </div>
                  </section>
                );
              })}
            </div>

            <div className="mt-5 mb-12 flex justify-between gap-x-3 mx-3">

              {/* <Link to={`/progress/${ref}`}>
           <div className="btn-gray-square flex justify-between items-center w-[170px] sm:w-auto">
             <div className="px-3 py-1.5">
               <div className="flex justify-center items-center bg-[#474747] rounded-full">
                 <FontAwesomeIcon
                   icon={faCircleChevronLeft}
                   className="text-[#9A9A9A]"
                 />
               </div>
             </div>
             <div className="flex-grow flex justify-center items-center py-2 text-lg">
               กลับ
             </div>
           </div>
         </Link> */}
              {/* <Link to={`/summary-job`} className="w-44 sm:w-auto"> */}
              <div
                className="btn-green-square text-lg flex justify-between items-center w-44 sm:w-auto"
                onClick={addList}
              >
                <div className="px-1 sm:px-3 ">
                  <div className="flex justify-center items-center bg-[#474747] rounded-full">
                    <FontAwesomeIcon
                      icon={faCirclePlus}
                      className="text-[#9A9A9A]"
                    />
                  </div>
                </div>
                <div className="flex-grow flex justify-center items-center px-2 h-full">
                  เพิ่มรายการแจ้งซ่อม
                </div>
              </div>
              <button type="submit" className="btn-black-square text-lg flex justify-between items-center w-44 sm:w-auto">
                <div className="flex-grow flex justify-center px-2">
                  ดำเนินการเสร็จสิ้น
                </div>
                <div className="px-1 sm:px-3 py-3.5 flex items-center">
                  <div className="flex justify-center items-center bg-[#474747] rounded-full">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#9A9A9A]"
                    />
                  </div>
                </div>
              </button>
              {/* </Link> */}
            </div>
          </div>
        </form>
      }
      {step == 2 && <>
        <form onSubmit={doneTaskCustomer}>
          <div className="container max-w-3xl mx-auto py-8">
            <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
              <div>
                <div className="flex justify-between py-5 pl-5 pr-3 sm:px-12">
                  <div className="text-lg sm:text-2xl">
                    เลขที่แจ้งซ่อม : {order.main}
                  </div>
                  <div className="flex items-center gap-x-1 px-3 sm:py-1 bg-yellow rounded-full">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-[#6A8680] text-xl sm:text-2xl"
                    />
                    <div className="sm:text-xl  font-semibold">
                      {order.zone}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 sm:px-12">
                <div className="flex items-center gap-x-3">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                    {`${ConvertDate(order.date, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.period)})`}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-2">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">สถานที่ :</span> {order.addressUser}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-2">
                  <div className="text-center w-5">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xl sm:text-2xl text-gray"
                    />
                  </div>
                  <div className="sm:text-2xl">
                    <span className="text-gray">ผู้ใช้บริการ :</span> {order.name}, Tel : {order.phone}
                  </div>
                </div>

              </div>
              {order && summaryList.map((item, index) => {
                return (
                  <div className="px-5 pt-2 pb-5 sm:px-12">
                    {index === 0 ? (
                      <div className="text-2xl sm:text-3xl mb-3 font-semibold">
                        รายการแจ้งซ่อม
                      </div>
                    ) : (
                      <div className="mt-3"></div>
                    )}
                    <div>
                      <div className="flex items-center">
                        <div className="flex-grow rounded-[25px] pl-4 pr-5 sm:pl-[45px] sm:pr-[25px] border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
                          <div className="py-3 sm:py-6">
                            <div className="flex justify-between w-full">
                              <div className="flex-1 flex gap-x-2 items-center">
                                <Switch
                                  checked={item.enabled}
                                  disabled={true}
                                  onChange={(value) => {
                                    let arr = [...listRepair];
                                    arr[index].enabled = value;
                                    setListRepair([...arr]);
                                  }}
                                  className={`${item.enabled ? 'bg-blue' : 'bg-gray-light'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">ซ่อมเรียบร้อย</span>
                                  <span
                                    className={`${item.enabled ? 'translate-x-6' : 'translate-x-1'
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                                <div className="text-xl sm:text-2xl">
                                  รายการที่ {index + 1}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-x-5 mt-3">
                              <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg">
                                {item.catalogName}
                              </div>
                              <div className="text-lg sm:text-xl">
                                {item.subCatalogName}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col mt-9">
                        <span className="text-xl">รายละเอียด</span>
                        <input
                          placeholder="กรุณากรอกรายละเอียด"
                          className="form-control text-xl"
                          readOnly={true}
                          value={item.detail}
                        />
                      </div>
                      <div className="flex flex-col mt-3">
                        <span className="text-xl">ภาพประกอบ/วิดีโอ</span>
                        <div className="flex items-center gap-x-3 flex-wrap">
                          {item.picture?.length > 0 &&
                            item.picture.map((pic, index) => {
                              return (
                                <div key={`picture-${index}`} className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[152px]">
                                  <img
                                    src={URL.createObjectURL(pic)}
                                    alt={`pic-${index}`}
                                    className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="px-5 pt-2 pb-5 sm:px-12">
                <div className="flex gap-x-4 items-center">
                  <span className="text-xl text-gray-dark">ลายเซ็น</span>
                  <button type="button" className="py-2 flex justify-center items-center text-2xl"
                    onClick={() => {
                      signatureRef.current.clear();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUndo}
                      className="fa transition duration-150 text-blue-dark"
                    />
                  </button>
                </div>
                <div className="p-3 border relative mt-3 flex-1" style={{
                  width: "fit-content",
                }}>
                  <SignatureCanvas penColor='black'
                    ref={signatureRef}
                    canvasProps={{ className: 'sigCanvas', height: 150, width: 350 }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5 mb-12 flex justify-between gap-x-3 mx-3">
              <button type="button" onClick={() => {
                setstep(1)
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 200);
              }} className="btn-gray-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                กลับ
              </button>
              <button type="submit" className="btn-black-square text-lg flex justify-between items-center w-44 sm:w-auto">
                <div className="flex-grow flex justify-center px-2">
                  ดำเนินการเสร็จสิ้น
                </div>
                <div className="px-1 sm:px-3 py-3.5 flex items-center">
                  <div className="flex justify-center items-center bg-[#474747] rounded-full">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#9A9A9A]"
                    />
                  </div>
                </div>
              </button>
              {/* </Link> */}
            </div>
          </div>
        </form>
      </>}
      {
        step == 3 && <>
          <form onSubmit={confirmTask}>
            <div className="container max-w-3xl mx-auto py-10">
              <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
                <div className="px-5 sm:px-12 pt-6 pb-8">
                  <div className="flex justify-between items-center mb-7">
                    <div onClick={() => {
                      let newDate = moment();
                      setdateNow(newDate);
                      const weeks = getWeeks(newDate.toDate());
                      setdateList(weeks);
                      getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                      getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                    }} className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                      Today
                    </div>
                    <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                      {
                        dateList.length > 0 && moment(dateList[0]).format('MMM YYYY')
                      }
                    </div>
                    <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                      <div onClick={() => {
                        let newDate = dateNow;
                        newDate.add(-7, 'days');
                        setdateNow(newDate);
                        const weeks = getWeeks(newDate.toDate());
                        getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                        setdateList(weeks);
                        getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                      }} className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                        <FontAwesomeIcon
                          icon={faCircleChevronLeft}
                          className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                        />
                      </div>
                      <div onClick={() => {
                        let newDate = dateNow;
                        newDate.add(7, 'days');
                        setdateNow(newDate);
                        const weeks = getWeeks(newDate.toDate());
                        getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                        setdateList(weeks);
                        getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                      }} className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                        <FontAwesomeIcon
                          icon={faCircleChevronRight}
                          className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex">
                      <div className="w-24 border border-[#dfdede]"></div>
                      <div className="flex-1 grid grid-cols-7">
                        {dateList.map((item, index) => {
                          return <div className="border flex flex-col flex-center border-[#dfdede] leading-3">
                            <span className="text-xl ">{ConvertDate(item, 'ddd')}</span>
                            <span className="text-2xl font-bold">{ConvertDate(item, 'DD')}</span>
                          </div>
                        })}

                      </div>
                    </div>
                    <div className="flex h-10">
                      <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">09:00-12:00 น.</div>
                      <div className="flex-1 grid grid-cols-7">
                        {dateList.map((item, index) => {
                          let selected = datePickList.map(x => x.date.toDate().toDateString()).includes(item.toDateString());
                          let datePeriod = datePickList.filter(x => x.date.toDate().toDateString() == item.toDateString());
                          return <div onClick={() => {
                            if (checkDate(item, 1)) {

                            } else {
                              if (slotCount > 1) {
                                checkSlotAvaiable(item, 1);
                              } else {
                                setDatePickList([{
                                  date: moment(item),
                                  period: 1
                                }]);
                              }

                            }
                          }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 1) ? "bg-[#e7e9ea]" : ""}
                      ${selected && datePeriod.map(x => x.period).includes(1)
                            && "bg-[#000] text-white"}`}>
                            {!(checkDate(item, 1)) &&
                              <div className="flex-center gap-x-1">
                                <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                                <span className="">ว่าง</span>
                              </div>
                            }
                          </div>
                        })}
                      </div>
                    </div>
                    <div className="flex h-10">
                      <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">13:00-17:00 น.</div>
                      <div className="flex-1 grid grid-cols-7">
                        {dateList.map((item, index) => {
                          let selected = datePickList.map(x => x.date.toDate().toDateString()).includes(item.toDateString());
                          let datePeriod = datePickList.filter(x => x.date.toDate().toDateString() == item.toDateString());
                          return <div onClick={() => {
                            if (checkDate(item, 2)) {
                            } else {
                              if (slotCount > 1) {
                                checkSlotAvaiable(item, 2);
                              } else {
                                setDatePickList([{
                                  date: moment(item),
                                  period: 2
                                }]);
                              }
                            }
                          }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 2) ? "bg-[#e7e9ea]" : ""}
                      ${selected && datePeriod.map(x => x.period).includes(2)
                            && "bg-[#000] text-white"}`}>
                            {!(checkDate(item, 2)) &&
                              <div className="flex-center gap-x-1">
                                <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                                <span className="">ว่าง</span>
                              </div>
                            }
                          </div>
                        })}
                      </div>
                    </div>
                    <div className="flex h-10">
                      <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">เวลาใดก็ได้</div>
                      <div className="flex-1 grid grid-cols-7">
                        {dateList.map((item, index) => {
                          let selected = datePickList.map(x => x.date.toDate().toDateString()).includes(item.toDateString());
                          let datePeriod = datePickList.filter(x => x.date.toDate().toDateString() == item.toDateString());
                          return <div onClick={() => {
                            if (checkDate(item, 3)) {
                            } else {
                              setDatePickList([{
                                date: moment(item),
                                period: 3
                              }]);
                            }
                          }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 3) ? "bg-[#e7e9ea]" : ""}
                      ${selected && datePeriod.map(x => x.period).includes(3)
                            && "bg-[#000] text-white"}`}>
                            {!(checkDate(item, 3)) &&
                              <div className="flex-center gap-x-1">
                                <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                                <span className="">ว่าง</span>
                              </div>
                            }
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {datePickList.length > 0 &&
                  <div className="px-3 sm:px-11 pt-6 pb-9">
                    <div className="flex-grow flex justify-between items-baseline px-3 sm:px-9 py-1.5 border-[1px] border-[#797879] border-dashed rounded-full bg-[#e8e8e9]">
                      <div className="flex items-baseline gap-x-3">
                        <div className="">
                          <FontAwesomeIcon
                            icon={faCalendarDay}
                            className="text-xl sm:text-2xl"
                          />
                        </div>
                        {
                          slotCount > 1 ?
                            <div className="text-xl sm:text-2xl">{`${ConvertDate(datePickList[0].date, 'ddd, DD MMMM YYYY')} - ${ConvertDate(datePickList[datePickList.length - 1].date, 'ddd, DD MMMM YYYY')}`}</div> :
                            <div className="text-xl sm:text-2xl">{ConvertDate(datePickList[0].date, 'ddd, DD MMMM YYYY')}</div>
                        }

                      </div>
                      {
                        slotCount == 1 && <>
                          {datePickList[0].period == 1 && <div>
                            <span className="text-xl sm:text-2xl mr-6">ช่วงเช้า</span>
                            <span className="text-xl sm:text-2xl">09.00 - 12.00 น.</span>
                          </div>
                          }
                          {datePickList[0].period == 2 && <div>
                            <span className="text-xl sm:text-2xl mr-6">ช่วงบ่าย</span>
                            <span className="text-xl sm:text-2xl">13:00-17:00 น.</span>
                          </div>
                          }
                          {datePickList[0].period == 3 && <div>
                            <span className="text-xl sm:text-2xl">ช่วงเวลาใดก็ได้</span>
                          </div>
                          }
                        </>
                      }


                    </div>
                  </div>
                }

              </div>
              <div className="mt-5 mb-12 mx-2 flex justify-between">
                <button onClick={() => {
                  setstep(2);
                  window.scrollTo(0, 0)
                }} className="btn-gray-square flex justify-between items-center w-[170px] sm:w-auto">
                  <div className="px-3 py-1.5">
                    <div className="flex justify-center items-center bg-[#474747] rounded-full">
                      <FontAwesomeIcon
                        icon={faCircleChevronLeft}
                        className="text-[#9A9A9A]"
                      />
                    </div>
                  </div>
                  <div className="flex-grow flex justify-center items-center py-2 text-lg">
                    กลับ
                  </div>
                </button>
                <button type="submit" className="btn-black-square flex justify-center items-center py-2 text-lg w-[170px] sm:w-auto">
                  ยืนยันการนัดหมาย
                </button>
              </div>
            </div>
          </form>
        </>
      }
      {/* {modal && <ModalReview setModal={setModal} repair={order} onClick={sendRating} />} */}
    </div >
  );
};

export default CheckJob;
