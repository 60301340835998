import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCalendarDay,
  faCheckCircle,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import CSwlAlert from "../../utils/alert";
import ApiTransaction from "../../api/ApiTransaction";
import _ from 'lodash';
import { ConvertDate, getPeriod } from "../../utils";

const Progress = () => {
  let { ref } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState('');
  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRef(ref);
      if (response.status == 200) {
        const { data } = response.data;
        const groupItems = _(data)
          .groupBy('maRef')
          .map((items, key) => ({
            main: key,
            date: items[0].dateTime,
            period: items[0].period,
            zone: items[0].zone,
            status: items[0].status,
            name: items[0].name,
            addressUser: items[0].addressUser,
            phone: items[0].phone,
            items: items,
          }))
          .value();
        if (groupItems.filter(x => x.status == "FS").length > 0) {
          setOrder(groupItems[0]);
        } else {
          await CSwlAlert.SwalErr({ description: "ไม่พบรายการนี้" });
          navigate('/schedule')
        }

      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        navigate('/schedule')
      });
    }

  }

  return (
    <div className="container max-w-3xl mx-auto py-8">
      <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
        <div className="flex justify-between px-3 sm:px-12 py-6">
          <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3">
            <div className="text-center w-5">
              <FontAwesomeIcon
                icon={faCalendarDay}
                className="text-xl sm:text-2xl text-gray"
              />
            </div>
            <div className="sm:text-2xl">
              {`${ConvertDate(order.date, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.period)})`}
            </div>
          </div>
          <div className="flex gap-x-3 justify-center items-center px-1 sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
            <FontAwesomeIcon
              icon={faSpinner}
              className="text-xl sm:text-2xl text-gray"
            />
            <div className="text-lg sm:text-2xl">รอตรวจรับงาน</div>
          </div>
        </div>
        <div className="px-5 pt-2 pb-5 sm:px-12">
          <div className="flex justify-between items-baseline">
            <div className="text-2xl sm:text-3xl mb-3 font-semibold">
              รายการแจ้งซ่อม
            </div>
            <div className="text-lg sm:text-xl">
              เลขที่แจ้งซ่อม : {order.main}
            </div>
          </div>

          <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
            {order && order.items.map((work, index) => {
              return (
                <div className="py-3 sm:py-6 gap-y-1 flex flex-col">
                  <div className="text-xl sm:text-2xl">
                    รายการที่ {index + 1}
                  </div>
                  <div className="flex gap-x-5">
                    {/* <img src={item.images?.length > 0 && process.env.REACT_APP_IMAGE + item.images[0].pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" /> */}
                    <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg h-fit">
                      {work.catalogName}
                    </div>
                    <div className="text-lg sm:text-xl">
                      {work.subCatalogName}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="py-6 px-5 sm:px-12">
          <div className="text-xl sm:text-3xl mb-3">สถานที่</div>
          <div className="flex items-center gap-x-3 sm:gap-x-5">
            <div className="flex-shrink-0 w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] rounded-full border-2 border-gray"></div>
            <div>
              <div className="sm:text-2xl flex gap-x-2">
                <span>{order.name}</span>
                <span className="text-gray">Tel : {order.phone}</span>
              </div>
              <div className="sm:text-2xl">
                {order.addressUser}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between py-3 px-5 sm:px-12">
          <Link to={`/postpone/${ref}`}>
            <div className="btn-yellow-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto">
              <FontAwesomeIcon
                icon={faTools}
                className="text-black sm:w-[30px] sm:h-[30px] rounded-full"
              />
              แจ้งขยายเวลาซ่อม
            </div>
          </Link>
          <Link to={`/check-job/${ref}`}>
            <div className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-[#999999] bg-black sm:w-[30px] sm:h-[30px] rounded-full"
              />
              ตรวจรับงาน
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Progress;
