import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCalendarDay,
  faCircleChevronLeft,
  faStar,
  faPlayCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import CSwlAlert from "../../utils/alert";
import ApiTransaction from "../../api/ApiTransaction";
import _ from 'lodash';
import { ConvertDate, getPeriod } from "../../utils";

const JobDetail = () => {
  const { ref } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState('');
  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRef(ref);
      if (response.status == 200) {
        const { data } = response.data;
        const groupItems = _(data)
          .groupBy('maRef')
          .map((items, key) => ({
            main: key,
            date: items[0].dateTime,
            period: items[0].period,
            zone: items[0].zone,
            status: items[0].status,
            name: items[0].name,
            addressUser: items[0].addressUser,
            phone: items[0].phone,
            signature: items[0].signature,
            items: items,
          }))
          .value();
        if (groupItems.filter(x => x.status == "FS" || x.status == "CP" || x.status == "AF" || x.status == "WR").length > 0) {
          setOrder(groupItems[0]);
        } else {
          await CSwlAlert.SwalErr({ description: "ไม่พบรายการนี้" });
          navigate('/schedule')
        }

      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        navigate('/schedule')
      });
    }

  }

  const startProject = async () => {
    const confirm = await CSwlAlert.SwalConfirm(`คุณต้องการจะเริ่มงาน ${order.main} \n ใช่หรือไม่`);
    if (confirm.isConfirmed) {
      CSwlAlert.SwalLoad();
      navigator.geolocation.getCurrentPosition(async function (position) {
        try {
          const response = await ApiTransaction.update({
            maRef: order.main,
            maStatus: "FS",
            lat: position.coords.latitude.toString(),
            lng: position.coords.longitude.toString()
          });
          if (response.status == 200) {
            await CSwlAlert.SwalClose();
            navigate(`/progress/${order.main}`);
          }
        } catch (error) {
          const { data } = error.response;
          CSwlAlert.SwalErr({ description: data.message })
        }
      });
    }

    // const confirm = await CSwlAlert.SwalConfirm(`คุณต้องการจะเริ่มงาน ${order.main} \n ใช่หรือไม่`);
    // if (confirm.isConfirmed) {
    //   CSwlAlert.SwalLoad();
    //   try {
    //     const response = await ApiTransaction.update({
    //       maRef: order.main,
    //       maStatus: "FS"
    //     });
    //     if (response.status == 200) {
    //       await CSwlAlert.SwalClose();
    //       navigate(`/progress/${order.main}`);
    //     }
    //   } catch (error) {
    //     const { data } = error.response;
    //     CSwlAlert.SwalErr({ description: data.message })
    //   }
    // }
  }

  return (
    <div>
      {/* <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">เลขที่แจ้งซ่อม : {ref}</span>
        <div className="gap-4 flex">
          <Link to="/job">
            <span className="text-xl">ใบงานเข้าใหม่</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">เลขที่แจ้งซ่อม : {ref}</span>
        </div>
      </div> */}
      <div className="container max-w-3xl mx-auto py-8">
        <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div>
            <div className="flex justify-between py-5 pl-5 pr-3 sm:px-12">
              <div className="text-lg sm:text-2xl">
                เลขที่แจ้งซ่อม : {ref}
              </div>
              <div className="flex items-center gap-x-1 px-3 sm:py-1 bg-yellow rounded-full">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-[#6A8680] text-xl sm:text-2xl"
                />
                <div className="sm:text-xl  font-semibold">
                  {order.zone}
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 pt-2 sm:pt-5 pb-5 sm:px-12">
            <div className="text-2xl sm:text-3xl font-semibold">
              รายการแจ้งซ่อม
            </div>
            <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 mt-3 sm:mt-8 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
              {order && order.items.map((work, index) => {
                return (
                  <div className="flex flex-col divide-y-2 divide-dashed divide-[#797879]">
                    <div className="py-3 sm:py-6 gap-y-1 flex flex-col">
                      <div className="text-xl sm:text-2xl">
                        รายการที่ {index + 1}
                      </div>
                      <div className="flex gap-x-5">
                        {/* <img src={item.images?.length > 0 && process.env.REACT_APP_IMAGE + item.images[0].pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" /> */}
                        <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg h-fit">
                          {work.catalogName}
                        </div>
                        <div className="text-lg sm:text-xl">
                          {work.subCatalogName}
                        </div>
                      </div>
                    </div>
                    <div className="py-3 sm:py-6">
                      <div className="sm:text-xl">รายละเอียด</div>
                      <div className="text-lg sm:text-2xl">
                        {order.status == "CP" ? work.reviewItem : work.detailItem}
                      </div>
                      {/* <div className="flex gap-x-3 sm:mt-3">
                        <img
                          src="#"
                          alt=""
                          className="w-[100px] h-[90px] sm:w-[177px] sm:h-[155px] rounded-lg border-2 overflow-hidden"
                        />
                        <img
                          src="#"
                          alt=""
                          className="w-[100px] h-[90px] sm:w-[177px] sm:h-[155px] rounded-lg border-2 overflow-hidden"
                        />
                        <img
                          src="#"
                          alt=""
                          className="w-[100px] h-[90px] sm:w-[177px] sm:h-[155px] rounded-lg border-2 overflow-hidden"
                        />
                      </div> */}
                    </div>
                    {/* <div className="flex gap-x-3 sm:gap-x-8 items-center">
                      <img src={work.images?.length > 0 && process.env.REACT_APP_IMAGE + work.images[0].pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" />
                    </div> */}
                    <div className="mt-3">
                      {
                        (work.images && work.images.filter(x => x.type != "REVIEW").length > 0) &&
                        <div className="flex flex-col mt-2">
                          <div className="sm:text-xl">{`รูปภาพจากลูกค้า`}</div>
                          <div className="flex flex-wrap gap-2">
                            {
                              work.images?.length > 0 && work.images.filter(x => x.type != "REVIEW").map((item, index) => {
                                return <img src={process.env.REACT_APP_IMAGE + item.pathFile}
                                  className="flex-shrink-0 w-[80px] h-[80px] sm:w-[250px] sm:h-[250px] rounded-lg border-2 border-gray" />
                              })
                            }
                          </div>
                        </div>
                      }
                      {
                        (work.images && work.images.filter(x => x.type == "REVIEW").length > 0) &&
                        <div className="flex flex-col mt-2">
                          <div className="sm:text-xl">{`รูปภาพจาก Foreman`}</div>
                          <div className="flex flex-wrap gap-2">
                            {
                              work.images?.length > 0 && work.images.filter(x => x.type == "REVIEW").map((item, index) => {
                                return <img src={process.env.REACT_APP_IMAGE + item.pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[250px] sm:h-[250px] rounded-lg border-2 border-gray" />
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="py-6 px-5 sm:px-12">
            <div className="flex items-center gap-x-3">
              <div className="text-center w-5">
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="text-xl sm:text-2xl text-gray"
                />
              </div>
              <div className="sm:text-2xl">
                <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                {`${ConvertDate(order.date, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.period)})`}
              </div>
            </div>
            {order.status == "CP" &&
              <>
                <div className="flex items-center gap-x-3 mt-2">
                  <span className="text-gray text-2xl">คะแนน :</span>
                  {order && order.items[0].rating != 0 ?
                    [1, 2, 3, 4].map((num, index) => {
                      return num <= order.items[0].rating ? (
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-xl sm:text-2xl text-yellow"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-xl sm:text-2xl text-gray-light opacity-60"
                        />
                      );
                    }) :
                    <div className="text-xl text-gray py-2">
                      - - -
                    </div>}
                </div>
                <div className="flex flex-col gap-y-2 mt-2">
                  <span className="text-gray sm:text-2xl">ลายเซ็น :</span>{" "}
                  {
                    order.signature &&
                    <img
                      src={process.env.REACT_APP_IMAGE + order.signature}
                      alt=""
                      className="w-[100px] h-[90px] sm:w-[177px] sm:h-[155px] rounded-lg border-2 overflow-hidden"
                    />
                  }
                </div>
              </>
            }

          </div>
          <div className="py-6 px-5 sm:px-12">
            <div className="text-xl sm:text-3xl mb-3">สถานที่</div>
            <div className="flex items-center gap-x-3 sm:gap-x-5">
              <div className="flex-shrink-0 w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] rounded-full border-2 border-gray"></div>
              <div>
                <div className="sm:text-2xl flex gap-x-2">
                  <span>{order.name}</span>
                  <span className="text-gray">Tel : {order.phone}</span>
                </div>
                <div className="sm:text-2xl">
                  {order.addressUser}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-12 mx-2 flex justify-between">
          <Link to={`/history`}>
            <div className="btn-gray-square flex justify-between items-center w-[170px] sm:w-auto">
              <div className="px-3 py-1.5">
                <div className="flex justify-center items-center bg-[#474747] rounded-full">
                  <FontAwesomeIcon
                    icon={faCircleChevronLeft}
                    className="text-[#9A9A9A]"
                  />
                </div>
              </div>
              <div className="flex-grow flex justify-center items-center py-2 text-lg">
                กลับ
              </div>
            </div>
          </Link>
          {
            order.status == "AF" &&
            // <Link onClick={() => startProject()}>
            <div onClick={() => startProject()} className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto cursor-pointer">
              <FontAwesomeIcon
                icon={faPlayCircle}
                className="text-[#999999] bg-black sm:w-[30px] sm:h-[30px] rounded-full"
              />
              เริ่มงาน
            </div>
            // </Link>
          }
          {
            order.status == "FS" &&
            // <Link onClick={() => startProject()}>
            <Link to={`/check-job/${order.main}`}>
              <div className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-[#999999] bg-black sm:w-[30px] sm:h-[30px] rounded-full"
                />
                ตรวจรับงาน
              </div>
            </Link>
            // </Link>
          }
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
