import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCalendarDay,
  faUser,
  faTrashAlt,
  faCirclePlus,
  faCircleChevronRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SummaryJob = () => {
  const [listRepair, setListRepair] = useState([
    {
      repair: "ระบบไฟ",
      jobType: "หลอดไฟชำรุด",
      detail: "โคมไฟบริเวณหน้าบ้านเปิดแล้วไม่ติด และมีน้ำขังอยู่ในโคมไฟ",
      picture: [{ link: "../../assets/images/pic_test.jpg" }],
      video: [],
    },
    {
      repair: "เครื่องใช้ไฟฟ้า",
      jobType: "ปั๊มน้ำชำรุด",
      detail: "โคมไฟบริเวณหน้าบ้านเปิดแล้วไม่ติด และมีน้ำขังอยู่ในโคมไฟ",
      picture: [{ link: "../../assets/images/pic_test.jpg" }],
      video: [],
    },
  ]);

  const [addRepair, setAddRepair] = useState([]);
  const [modal, setModal] = useState(false);

  const [itemRemove, setItemRemove] = useState({});

  return (
    <div>
      <div className="bg-black text-white text-xl py-2 text-center">
        สรุปใบงาน
      </div>
      <div className="container max-w-3xl mx-auto py-8">
        <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div>
            <div className="flex justify-between py-5 pl-5 pr-3 sm:px-12">
              <div className="text-lg sm:text-2xl">
                เลขที่แจ้งซ่อม : AF20220726-1
              </div>
              <div className="flex items-center gap-x-1 px-3 sm:py-1 bg-yellow rounded-full">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-[#6A8680] text-xl sm:text-2xl"
                />
                <div className="sm:text-xl  font-semibold">
                  กาจนาภิเษก-ราชพฤกษ์
                </div>
              </div>
            </div>
          </div>
          <div className="py-6 px-5 sm:px-12">
            <div className="flex items-center gap-x-3">
              <div className="text-center w-5">
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="text-xl sm:text-2xl text-gray"
                />
              </div>
              <div className="sm:text-2xl">
                <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                วันอังคาร ที่ 26 กค. 2564 ( ช่วงเช้า 09:00 - 12:00 )
              </div>
            </div>
            <div className="flex items-center gap-x-3 mt-2">
              <div className="text-center w-5">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-xl sm:text-2xl text-gray"
                />
              </div>
              <div className="sm:text-2xl">
                <span className="text-gray">สถานที่ :</span> 643/56 โครงการ โนระ
                ซอย 3/2, โซน กาจนาภิเษก-ราชพฤกษ์
              </div>
            </div>
            <div className="flex items-center gap-x-3 mt-2">
              <div className="text-center w-5">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-xl sm:text-2xl text-gray"
                />
              </div>
              <div className="sm:text-2xl">
                <span className="text-gray">ผู้ใช้บริการ :</span> คุณ ยชญ์ธนัฎฐ์
                ธรรมปรีชานันต์, Tel : 081-2345678
              </div>
            </div>
          </div>
          {listRepair.map((item, index) => {
            return (
              <div className="px-5 pt-2 pb-5 sm:px-12">
                {index === 0 ? (
                  <div className="text-2xl sm:text-3xl mb-3 font-semibold">
                    รายการแจ้งซ่อม
                  </div>
                ) : (
                  <div className="mt-3"></div>
                )}
                <div>
                  <div className="flex-grow rounded-[25px] pl-4 pr-5 sm:pl-[45px] sm:pr-[25px] border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
                    <div className="py-3 sm:py-6">
                      <div className="text-xl sm:text-2xl">
                        รายการที่ {index + 1}
                      </div>
                      <div className="flex gap-x-5">
                        <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg">
                          {item.repair}
                        </div>
                        <div className="text-lg sm:text-xl">{item.jobType}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-9">
                    <span className="text-xl">รายละเอียด</span>
                    <input
                      placeholder="กรุณากรอกรายละเอียด"
                      className="form-control text-xl"
                      value={item.detail}
                      onChange={(e) => {
                        let arr = [...listRepair];
                        arr[index].detail = e.target.value;
                        setListRepair([...arr]);
                      }}
                      disabled
                    />
                  </div>
                  <div className="flex flex-col py-3">
                    <span className="text-xl">ภาพประกอบ/วิดีโอ</span>
                    <div className="flex items-center gap-x-3 overflow-x-auto">
                      {item.picture.length > 0 &&
                        item.picture.map((pic, index) => {
                          return (
                            <div className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[152px]">
                              <img
                                src={pic.link}
                                alt={`pic-${index}`}
                                className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mx-2 flex justify-center">
          <Link to={`/history`} className="flex-grow sm:flex-grow-0">
            <div className="btn-green-square text-lg text-center py-2">
              ยืนยัน
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SummaryJob;
