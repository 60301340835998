const Footer = () => {
    return <>
        <div className="w-100 border-b border-green-default"></div>
        <div className="flex p-10 flex-wrap bg-white">
            <div className="flex flex-col mr-10">
                <div className="pb-5">
                    <h1 className="whitespace-pre-line inline-block text-3xl text-gray-900 tracking-tight uppercase">{`Areeya 
            property 
            pcl.`}</h1>
                </div>
                <div className="pb-5">
                    <h4 className="whitespace-pre-line">{`สำนักงานใหญ่
            บริษัท อารียา พรอพเพอร์ตี้ จำกัด (มหาชน)`}</h4>
                </div>
                <div className="pb-5">
                    <label className="whitespace-pre-line">{`999 ถนนประดิษฐ์มนูธรรม แขวงสะพานสอง
            เขตวังทองหลาง กรุงเทพมหานคร
            รหัสไปรษณีย์ 10310`}</label>
                </div>
            </div>
            <div className="flex-column w-full lg:flex-1 mr-10">
                <h3 className="pb-5">
                    ทำเลโครงการ
                </h3>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 whitespace-nowrap">
                    <div className="flex flex-col leading-10">
                        <span>บางนา - วงแหวนฯ</span>
                        <span>ลาดพร้าว - เสรีไทย</span>
                        <span>ลาดกระบัง-สุวรรณภูมิ</span>
                        <span>สุขุมวิท 77</span>
                        <span>เกษตร-นวมินทร์</span>
                    </div>
                    <div className="flex flex-col leading-10">
                        <span>บางนา - วงแหวนฯ</span>
                        <span>ลาดพร้าว - เสรีไทย</span>
                        <span>ลาดกระบัง-สุวรรณภูมิ</span>
                        <span>สุขุมวิท 77</span>
                        <span>เกษตร-นวมินทร์</span>
                    </div>
                    <div className="flex flex-col leading-10">
                        <span>บางนา - วงแหวนฯ</span>
                        <span>ลาดพร้าว - เสรีไทย</span>
                        <span>ลาดกระบัง-สุวรรณภูมิ</span>
                        <span>สุขุมวิท 77</span>
                        <span>เกษตร-นวมินทร์</span>
                    </div>
                </div>
            </div>
            <div className="flex-column">
                <h3 className="pb-5 uppercase">
                    Areeya family
                </h3>
                <div className="pb-5">
                    <label className="whitespace-pre-line">{`แอพพลิเคชั่นที่จะช่วยให้ชีวิตคุณง่ายขึ้น
            หมดกังวลเรื่องการแจ้งซ่อม อัพเดตข้อมูลข่าวสาร
            จัดการเรื่องบ้านได้ง่าย ครบ จบในแอพเดียว`}</label>
                </div>
                <div className="pb-3">
                    <div className="h-12 bg-contain bg-no-repeat"
                        style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/googlePlay.png")` }} />
                </div>
                <div>
                    <div className="h-12 bg-contain bg-no-repeat"
                        style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/appStore.png")` }} />
                </div>
            </div>
        </div>
    </>
}

export default Footer;