import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faSpinner,
  faMapMarkerAlt,
  faCircleCheck,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import CSwlAlert from "../../utils/alert";
import ApiTransaction from "../../api/ApiTransaction";
import { ConvertDate, getPeriod, getWeeks } from "../../utils";
import moment from "moment";
import ApiConfig from "../../api/ApiConfig";
import _ from 'lodash';
const PostPone = () => {
  const [state, setstate] = useState({
    reason: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  let { ref } = useParams();
  const location = useLocation();
  const [repair, setRepair] = useState("ระบบไฟ");
  const [order, setOrder] = useState('');

  const [dateNow, setdateNow] = useState(moment());
  const [dateList, setdateList] = useState([]);
  const [holidayList, setholidayList] = useState([]);
  const [datePickList, setDatePickList] = useState([]);
  const [configMaDate, setConfigMaDate] = useState("");
  //const [periodSelected, setperiodSelected] = useState();
  const [config, setConfig] = useState("");
  const [orderHistory, setorderHistory] = useState([]);
  const [slotCount, setslotCount] = useState(1);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    const promise2 = getConfigs();
    Promise.all([promise1, promise2]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  // useEffect(() => {
  //   const weeks = getWeeks(dateNow.toDate());
  //   getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
  //   setdateList(weeks);
  // }, []);

  const getTransactionWeeks = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiTransaction.getTaskWeeks({ dateFrom, dateTo, status: "" });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      const groupItems = _(data.filter(x => x.maRef !== ref))
        .groupBy(item => `"${item.maRef}+${item.dateTime}+${item.period}"`)
        .map((items, key) => ({
          main: key,
          date: items[0].dateTime,
          period: items[0].period,
          zone: items[0].zone,
          status: items[0].status,
          items: items,
        }))
        .value();
      console.log('groupItems', groupItems);
      setorderHistory(groupItems);
    }
  }

  // const getTransaction = async () => {
  //   try {
  //     const response = await ApiTransaction.getRef(ref);
  //     if (response.status == 200) {
  //       const { data } = response.data;
  //       const groupItems = _(data)
  //         .groupBy('maRef')
  //         .map((items, key) => ({
  //           main: key,
  //           date: items[0].dateTime,
  //           period: items[0].period,
  //           zone: items[0].zone,
  //           status: items[0].status,
  //           name: items[0].name,
  //           addressUser: items[0].addressUser,
  //           phone: items[0].phone,
  //           items: items,
  //         }))
  //         .value();
  //       console.log('groupItems::', groupItems)
  //       setOrder(groupItems[0]);
  //       setdateSelected(new Date(groupItems[0].date));
  //       setperiodSelected(groupItems[0].period)
  //       const weeks = getWeeks(moment(groupItems[0].date).toDate());
  //       getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
  //       getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
  //       setdateList(weeks);
  //     }
  //   } catch (error) {
  //     const { data } = error.response;
  //     CSwlAlert.SwalErr({ description: data.message }).then((result) => {
  //       navigate(-1)
  //     });
  //   }

  // }

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRefPostpone(ref);
      if (response.status == 200) {
        const { data } = response.data;
        setOrder(data);
        setslotCount(data.main.slot)
        // setdateSelected(new Date(data.main.madate));
        // setperiodSelected(data.items[0].period)
        const weeks = getWeeks(moment(data.main.madate).toDate());
        await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
        await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
        setdateList(weeks);
      }
    } catch (error) {
      console.log(error)
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        navigate(-1)
      });
    }

  }

  const getConfigs = async () => {
    const response = await ApiConfig.getConfigByCode('busy_date');
    const response2 = await ApiConfig.getConfigByCode('madate_day');
    if (response.status == 200) {
      const { data } = response.data;
      setConfig(data);
    }
    if (response2.status == 200) {
      setConfigMaDate(response2.data.data);
    }
  }

  const getHoliday = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiConfig.getHoliday({ dateFrom, dateTo });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      setholidayList(data);
    }
  }

  const checkDate = (date, period) => {
    if (period == 3) {
      if (slotCount > 1) {
        return true;
      }
    }
    const timeNow = moment().format('HH');
    if (period == 3 && moment(date).format("DD/MM/YYYY") == moment().add('days', configMaDate?.value).format("DD/MM/YYYY")) {
      return true;
    } else if (period == 2 && timeNow > 17 && moment(date).format("DD/MM/YYYY") == moment().add('days', configMaDate?.value).format("DD/MM/YYYY")) {
      return true;
    } else if (period == 1 && timeNow > 12 && moment(date).format("DD/MM/YYYY") == moment().add('days', configMaDate?.value).format("DD/MM/YYYY")) {
      return true;
    }
    const nowDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const pickDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const listHoliday = holidayList.map(x => new Date(x.holidaydate).toDateString())
    if (pickDate <= nowDate) {
      return true;
    }
    if (config?.value && config?.value.split(',').includes(ConvertDate(date, 'ddd'))) {
      return true;
    } else if (listHoliday.includes(date.toDateString())) {
      return true;
    }
    else if (orderHistory.filter(x => x.period == period).map(x => new Date(x.date).toDateString()).includes(date.toDateString())) {
      return true;
    }
    else {
      return false;
    }
  }

  const updateRef = async (e) => {
    e.preventDefault();
    CSwlAlert.SwalLoad();
    try {
      const response = await ApiTransaction.update({
        maRef: ref,
        maStatus: order?.main?.mastatus,
        maReason: state.reason,
        appointmentLists: datePickList,
        // appointmentDate: dateSelected,
        // period: periodSelected?.toString(),
        type: "postpone"
      });
      if (response.status == 200) {
        CSwlAlert.SwalSuccess("", { html: "ยืนยันการนัดหมายสำเร็จ" }).then((result) => {
          navigate('/progress');
        })
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  const checkSlotAvaiable = (date, period) => {
    const listHoliday = holidayList.map(x => new Date(x.holidaydate).toDateString())
    let dateList = [];
    let dateListItem = [];
    if (period == 1) {
      let daysOfslot = (slotCount / 2);
      for (let i = 0; i < daysOfslot; i++) {
        let dateAdd = moment(date).add(i, 'days');
        for (let j = 1; j < 3; j++) {
          if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
            dateList.push(false)
          } else {
            if (listHoliday.includes(dateAdd.toDate().toDateString())) {
              dateList.push(false)
            } else {
              if (listHoliday.includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                if (orderHistory.filter(x => x.period == j).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                  dateList.push(false)
                } else {
                  dateListItem.push({
                    date: dateAdd,
                    period: j
                  });
                  dateList.push(true)
                }
              }
            }

          }
        }

      }
    }
    else if (period == 2) {
      let daysOfslot = (slotCount / 2) + 1;
      for (let i = 0; i < daysOfslot; i++) {
        let dateAdd = moment(date).add(i, 'days');
        if (i == 0) {
          if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
            dateList.push(false)
          } else {
            if (listHoliday.includes(dateAdd.toDate().toDateString())) {
              dateList.push(false)
            } else {
              if (orderHistory.filter(x => x.period == 2).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                dateListItem.push({
                  date: dateAdd,
                  period: 2
                });
                dateList.push(true)
              }
            }

          }
        }
        else if (i == (daysOfslot - 1)) {
          if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
            dateList.push(false)
          } else {
            if (listHoliday.includes(dateAdd.toDate().toDateString())) {
              dateList.push(false)
            } else {
              if (orderHistory.filter(x => x.period == 1).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                dateListItem.push({
                  date: dateAdd,
                  period: 1
                });
                dateList.push(true)
              }
            }

          }
        }
        else {
          for (let j = 1; j < 3; j++) {
            if (config?.value.split(',').includes(ConvertDate(dateAdd, 'ddd'))) {
              dateList.push(false)
            } else {
              if (listHoliday.includes(dateAdd.toDate().toDateString())) {
                dateList.push(false)
              } else {
                if (orderHistory.filter(x => x.period == j).map(x => new Date(x.date).toDateString()).includes(dateAdd.toDate().toDateString())) {
                  dateList.push(false)
                } else {
                  dateListItem.push({
                    date: dateAdd,
                    period: j
                  });
                  dateList.push(true)
                }
              }

            }
          }
        }

      }
    }
    if (slotCount !== dateList.filter(x => x == true).length) {
      CSwlAlert.SwalErr({ description: `กรุณาเลือกวันที่ว่างติดต่อกัน ${slotCount} Slot` })
    } else {
      setDatePickList(dateListItem);
    }
  }

  return (
    <div>
      <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">ขอเลื่อนวันบริการ</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to="/progress">
            <span className="text-xl">งานที่กำลังทำ</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to={`/job-detail/${ref}`}>
            <span className="text-xl">เลขที่แจ้งซ่อม : {ref}</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">ขอเลื่อนวันบริการ</span>
        </div>
      </div>
      <form onSubmit={updateRef}>
        <div className="container max-w-3xl mx-auto py-10">
          <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="flex justify-between px-3 sm:px-12 py-6">
              <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3">
                <div className="text-xl sm:text-3xl">ประเภท</div>
                <div className="text-xl sm:text-3xl text-white bg-black px-3 sm:px-6 rounded-full">
                  {"แจ้งซ่อม"}
                </div>
                <div className="text-sm sm:text-2xl">
                  เลขที่แจ้งซ่อม : {ref}
                </div>
              </div>
              {repair.status === "finish" ? (
                <div className="flex gap-x-3 justify-center items-center sm:px-4 rounded-lg bg-green-500 w-[115px] sm:w-[200px]">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-xl sm:text-2xl text-gray bg-black rounded-full"
                  />
                  <div className="text-lg sm:text-2xl">เสร็จแล้ว</div>
                </div>
              ) : repair.status === "wait_inspect" ? (
                <div className="flex gap-x-3 justify-center items-center sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="text-lg sm:text-2xl">รอตรวจรับงาน</div>
                </div>
              ) : (
                order?.main?.mastatus === "W" && (
                  <div className="flex justify-center items-center gap-x-3 sm:px-4 rounded-lg bg-[#fbf2c9] w-[115px] sm:w-[200px]">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="text-lg sm:text-2xl text-gray"
                    />
                    <div className="text-lg sm:text-2xl">รอดำเนินการ</div>
                  </div>
                )
              )}
            </div>
            {
              order && <div className="py-6 px-5 sm:px-12">
                <div className="flex items-center gap-x-3">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="sm:text-2xl">
                    <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                    {`${ConvertDate(order.main.madate, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.main.period)})`}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-3">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="sm:text-2xl">
                    <span className="text-gray">สถานที่ :</span> {order.user.addressNo}
                  </div>
                </div>
              </div>
            }
          </div>
          {/* <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="px-5 sm:px-12 pt-6 pb-8">
              <div className="flex justify-between items-center mb-7">
                <div onClick={() => {
                  let newDate = moment();
                  setdateNow(newDate);
                  const weeks = getWeeks(newDate.toDate());
                  getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                  setdateList(weeks);
                  getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                }} className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                  Today
                </div>
                <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                  {
                    dateList.length > 0 && moment(dateList[0]).format('MMM YYYY')
                  }
                </div>
                <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                  <div onClick={() => {
                    let newDate = dateNow;
                    newDate.add(-7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                    setdateList(weeks);
                    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                  }} className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronLeft}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                  <div onClick={() => {
                    let newDate = dateNow;
                    newDate.add(7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                    setdateList(weeks);
                    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                  }} className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex">
                  <div className="w-24 border border-[#dfdede]"></div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div className="border flex flex-col flex-center border-[#dfdede] leading-3">
                        <span className="text-xl ">{ConvertDate(item, 'ddd')}</span>
                        <span className="text-2xl font-bold">{ConvertDate(item, 'DD')}</span>
                      </div>
                    })}

                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">09:00-12:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 1)) {

                        } else {
                          setdateSelected(item);
                          setperiodSelected(1);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 1) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected?.toDateString() == item.toDateString() && periodSelected == 1 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 1)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">13:00-17:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 2)) {
                        } else {
                          setdateSelected(item);
                          setperiodSelected(2);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 2) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected?.toDateString() == item.toDateString() && periodSelected == 2 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 2)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">เวลาใดก็ได้</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 3)) {
                        } else {
                          setdateSelected(item);
                          setperiodSelected(3);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 3) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected?.toDateString() == item.toDateString() && periodSelected == 3 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 3)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
            {dateSelected &&
              <div className="px-3 sm:px-11 pt-6 pb-9">
                <div className="flex-grow flex justify-between items-baseline px-3 sm:px-9 py-1.5 border-[1px] border-[#797879] border-dashed rounded-full bg-[#e8e8e9]">
                  <div className="flex items-baseline gap-x-3">
                    <div className="">
                      <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="text-xl sm:text-2xl"
                      />
                    </div>
                    <div className="text-xl sm:text-2xl">{ConvertDate(dateSelected, 'ddd, DD MMMM YYYY')}</div>
                  </div>
                  {periodSelected == 1 && <div>
                    <span className="text-xl sm:text-2xl mr-6">ช่วงเช้า</span>
                    <span className="text-xl sm:text-2xl">09.00 - 12.00 น.</span>
                  </div>
                  }
                  {periodSelected == 2 && <div>
                    <span className="text-xl sm:text-2xl mr-6">ช่วงบ่าย</span>
                    <span className="text-xl sm:text-2xl">13:00-17:00 น.</span>
                  </div>
                  }
                  {periodSelected == 3 && <div>
                    <span className="text-xl sm:text-2xl">ช่วงเวลาใดก็ได้</span>
                  </div>
                  }
                </div>
                <div className="pt-6 sm:py-9 flex flex-col">
                  <span className="text-xl required">ผลเหตุการแจ้งเลื่อนบริการ</span>
                  <input
                    required
                    onChange={(e) => {
                      setstate({ ...state, reason: e.target.value })
                    }}
                    value={state.reason}
                    //   placeholder="กรุณากรอกรายละเอียดเรื่องร้องเรียน"
                    className="form-control text-xl"
                  />
                </div>
              </div>
            }

          </div> */}
          <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="px-5 sm:px-12 pt-6 pb-8">
              <div className="flex justify-between items-center mb-7">
                <div onClick={async() => {
                  let newDate = moment();
                  setdateNow(newDate);
                  const weeks = getWeeks(newDate.toDate());
                  setdateList(weeks);
                  await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "load" })
                  await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                }} className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                  Today
                </div>
                <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                  {
                    dateList.length > 0 && moment(dateList[0]).format('MMM YYYY')
                  }
                </div>
                <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                  <div onClick={async() => {
                    let newDate = dateNow;
                    newDate.add(-7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1],type: "load" })
                    setdateList(weeks);
                    await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                  }} className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronLeft}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                  <div onClick={async() => {
                    let newDate = dateNow;
                    newDate.add(7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "load" })
                    setdateList(weeks);
                    await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                  }} className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex">
                  <div className="w-24 border border-[#dfdede]"></div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div className="border flex flex-col flex-center border-[#dfdede] leading-3">
                        <span className="text-xl ">{ConvertDate(item, 'ddd')}</span>
                        <span className="text-2xl font-bold">{ConvertDate(item, 'DD')}</span>
                      </div>
                    })}

                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">09:00-12:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      let selected = datePickList.map(x => x.date.toDate().toDateString()).includes(item.toDateString());
                      let datePeriod = datePickList.filter(x => x.date.toDate().toDateString() == item.toDateString());
                      return <div onClick={() => {
                        if (checkDate(item, 1)) {

                        } else {
                          if (slotCount > 1) {
                            checkSlotAvaiable(item, 1);
                          } else {
                            setDatePickList([{
                              date: moment(item),
                              period: 1
                            }]);
                          }

                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 1) ? "bg-[#e7e9ea]" : ""}
                      ${selected && datePeriod.map(x => x.period).includes(1)
                        && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 1)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">13:00-17:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      let selected = datePickList.map(x => x.date.toDate().toDateString()).includes(item.toDateString());
                      let datePeriod = datePickList.filter(x => x.date.toDate().toDateString() == item.toDateString());
                      return <div onClick={() => {
                        if (checkDate(item, 2)) {
                        } else {
                          if (slotCount > 1) {
                            checkSlotAvaiable(item, 2);
                          } else {
                            setDatePickList([{
                              date: moment(item),
                              period: 2
                            }]);
                          }
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 2) ? "bg-[#e7e9ea]" : ""}
                      ${selected && datePeriod.map(x => x.period).includes(2)
                        && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 2)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">เวลาใดก็ได้</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      let selected = datePickList.map(x => x.date.toDate().toDateString()).includes(item.toDateString());
                      let datePeriod = datePickList.filter(x => x.date.toDate().toDateString() == item.toDateString());
                      return <div onClick={() => {
                        if (checkDate(item, 3)) {
                        } else {
                          setDatePickList([{
                            date: moment(item),
                            period: 3
                          }]);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 3) ? "bg-[#e7e9ea]" : ""}
                      ${selected && datePeriod.map(x => x.period).includes(3)
                        && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 3)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
            {datePickList.length > 0 &&
              <div className="px-3 sm:px-11 pt-6 pb-9">
                <div className="flex-grow flex justify-between items-baseline px-3 sm:px-9 py-1.5 border-[1px] border-[#797879] border-dashed rounded-full bg-[#e8e8e9]">
                  <div className="flex items-baseline gap-x-3">
                    <div className="">
                      <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="text-xl sm:text-2xl"
                      />
                    </div>
                    {
                      slotCount > 1 ?
                        <div className="text-xl sm:text-2xl">{`${ConvertDate(datePickList[0].date, 'ddd, DD MMMM YYYY')} - ${ConvertDate(datePickList[datePickList.length - 1].date, 'ddd, DD MMMM YYYY')}`}</div> :
                        <div className="text-xl sm:text-2xl">{ConvertDate(datePickList[0].date, 'ddd, DD MMMM YYYY')}</div>
                    }

                  </div>
                  {
                    slotCount == 1 && <>
                      {datePickList[0].period == 1 && <div>
                        <span className="text-xl sm:text-2xl mr-6">ช่วงเช้า</span>
                        <span className="text-xl sm:text-2xl">09.00 - 12.00 น.</span>
                      </div>
                      }
                      {datePickList[0].period == 2 && <div>
                        <span className="text-xl sm:text-2xl mr-6">ช่วงบ่าย</span>
                        <span className="text-xl sm:text-2xl">13:00-17:00 น.</span>
                      </div>
                      }
                      {datePickList[0].period == 3 && <div>
                        <span className="text-xl sm:text-2xl">ช่วงเวลาใดก็ได้</span>
                      </div>
                      }
                    </>
                  }


                </div>
                <div className="pt-6 sm:py-9 flex flex-col">
                  <span className="text-xl required">ผลเหตุการแจ้งเลื่อนบริการ</span>
                  <input
                    required
                    onChange={(e) => {
                      setstate({ ...state, reason: e.target.value })
                    }}
                    value={state.reason}
                    //   placeholder="กรุณากรอกรายละเอียดเรื่องร้องเรียน"
                    className="form-control text-xl"
                  />
                </div>
              </div>
            }

          </div>
          <div className="mt-5 mb-12 mx-2 flex justify-between">
            <Link to={`/repair-status/${ref}`}>
              <div className="btn-gray-square flex justify-between items-center w-[170px] sm:w-auto">
                <div className="px-3 py-1.5">
                  <div className="flex justify-center items-center bg-[#474747] rounded-full">
                    <FontAwesomeIcon
                      icon={faCircleChevronLeft}
                      className="text-[#9A9A9A]"
                    />
                  </div>
                </div>
                <div className="flex-grow flex justify-center items-center py-2 text-lg">
                  กลับ
                </div>
              </div>
            </Link>
            <button type="submit" className="btn-black-square flex justify-center items-center py-2 text-lg w-[170px] sm:w-auto">
              ยืนยันการนัดหมาย
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostPone;
