import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleChevronRight,
  faCircleChevronLeft,
  faSpinner,
  faCalendarDay,
  faPlayCircle,
  faCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import CSwlAlert from "../../utils/alert";
import { ConvertDate, getWeeks } from "../../utils";
import ApiConfig from '../../api/ApiConfig'
import moment from 'moment';
import ApiTransaction from "../../api/ApiTransaction";
import _ from 'lodash';

const Schedule = () => {
  const navigate = useNavigate();
  const [dateNow, setdateNow] = useState(moment());
  const [dateList, setdateList] = useState([]);
  const [holidayList, setholidayList] = useState([]);
  const [dateSelected, setdateSelected] = useState();
  const [periodSelected, setperiodSelected] = useState();
  const [catalogs, setCatalogs] = useState([]);
  const [subcatalogs, setSubCatalogs] = useState([]);
  const [config, setConfig] = useState("");
  const [orderHistory, setorderHistory] = useState([]);
  const [orderSelected, setOrderSelected] = useState({
    items: []
  });

  useEffect(() => {
    const weeks = getWeeks(dateNow.toDate());
    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" });
    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
    setdateList(weeks);
  }, []);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getConfigs();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getConfigs = async () => {
    const response = await ApiConfig.getConfigByCode('busy_date');
    if (response.status == 200) {
      const { data } = response.data;
      console.log('config', data);
      setConfig(data);
    }
  }

  const getHoliday = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiConfig.getHoliday({ dateFrom, dateTo });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      setholidayList(data);
    }
  }

  const getTransactionWeeks = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiTransaction.getTaskWeeks({ dateFrom, dateTo, status: "" });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      const groupItems = _(data)
        .groupBy(item => `"${item.maRef}+${item.dateTime}+${item.period}"`)
        .map((items, key) => ({
          main: key,
          maRef: items[0].maRef,
          date: items[0].dateTime,
          period: items[0].period,
          zone: items[0].zone,
          addressUser: items[0].addressUser,
          projectName: items[0].projectName,
          status: items[0].status,
          items: items,
        }))
        .value();
      setorderHistory(groupItems);
    }
  }

  const checkDate = (date, period) => {
    const nowDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const pickDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const listHoliday = holidayList.map(x => new Date(x.holidaydate).toDateString())
    if (pickDate <= nowDate) {
      return true;
    }
    //alert(ConvertDate(date, 'ddd'))
    if (config?.value && config?.value.split(',').includes(ConvertDate(date, 'ddd'))) {
      return true;
    } else if (listHoliday.includes(date.toDateString())) {
      return true;
    }
    else if (orderHistory.filter(x => x.period == period).map(x => new Date(x.date).toDateString()).includes(date.toDateString())) {
      return false;
    }
    else {
      return false;
    }
  }

  const checkDateText = (date, period) => {
    if (orderHistory.filter(x => new Date(x.date).toDateString() == date.toDateString() && x.period == period)?.length > 0) {
      return `${orderHistory.find(x => new Date(x.date).toDateString() == date.toDateString() && x.period == period)?.addressUser}`
    } else {
      return `ว่าง`;
    }
  }

  const getOrderHistorySelected = (date, period) => {
    if (orderHistory.filter(x => new Date(x.date).toDateString() == date.toDateString() && x.period == period)?.length > 0) {
      return orderHistory.find(x => new Date(x.date).toDateString() == date.toDateString() && x.period == period);
    } else {
      return {};
    }
  }

  const startProject = async () => {
    const confirm = await CSwlAlert.SwalConfirm(`คุณต้องการจะเริ่มงาน ${orderSelected.maRef} \n ใช่หรือไม่`);
    if (confirm.isConfirmed) {
      CSwlAlert.SwalLoad();
      try {
        const response = await ApiTransaction.update({
          maRef: orderSelected.maRef,
          maStatus: "FS"
        });
        if (response.status == 200) {
          await CSwlAlert.SwalClose();
          navigate(`/progress/${orderSelected.maRef}`);
        }
      } catch (error) {
        const { data } = error.response;
        CSwlAlert.SwalErr({ description: data.message })
      }
    }
  }


  return (
    <div>
      <div className="container max-w-3xl mx-auto py-8">
        <form>
          <div className="bg-white rounded-lg mb-4 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="px-5 sm:px-12 pt-6 pb-8">
              <div className="flex justify-between items-center mb-7">
                <div onClick={async() => {
                  let newDate = moment();
                  setdateNow(newDate);
                  const weeks = getWeeks(newDate.toDate());
                  setdateList(weeks);
                  await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] });
                  await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] });
                }} className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                  Today
                </div>
                <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                  {
                    dateList.length > 0 && moment(dateList[0]).format('MMM YYYY')
                  }
                </div>
                <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                  <div onClick={async() => {
                    let newDate = dateNow;
                    newDate.add(-7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    setdateList(weeks);
                    await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                    await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] });
                  }} className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronLeft}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                  <div onClick={async() => {
                    let newDate = dateNow;
                    newDate.add(7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    setdateList(weeks);
                    
                    await getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                    await getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] });
                  }} className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex">
                  <div className="w-24 border border-[#dfdede]"></div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div className="border flex flex-col flex-center border-[#dfdede] leading-3">
                        <span className="text-xl ">{ConvertDate(item, 'ddd')}</span>
                        <span className="text-2xl font-bold">{ConvertDate(item, 'DD')}</span>
                      </div>
                    })}

                  </div>
                </div>
                <div className="flex">
                  <div className="w-24 flex-center items-center border border-[#dfdede]">
                    <span>09:00-12:00 น.</span>
                  </div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 1)) {

                        } else {
                          if (checkDateText(item, 1) !== "ว่าง") {
                            setdateSelected(item);
                            setperiodSelected(1);
                            setOrderSelected(getOrderHistorySelected(item, 1));
                          }
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 1) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 1 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 1)) &&
                          <div className="flex-center gap-x-1 py-4 px-2">
                            {checkDateText(item, 1) == "ว่าง" && <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />}
                            <span className="" dangerouslySetInnerHTML={{ __html: checkDateText(item, 1) }}></span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-24 flex-center items-center border border-[#dfdede]">13:00-17:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 2)) {
                        } else {
                          if (checkDateText(item, 2) !== "ว่าง") {
                            setdateSelected(item);
                            setperiodSelected(2);
                            setOrderSelected(getOrderHistorySelected(item, 2));
                          }
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 2) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 2 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 2)) &&
                          <div className="flex-center gap-x-1 py-4 px-2">
                            {checkDateText(item, 2) == "ว่าง" && <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />}
                            <span className="" dangerouslySetInnerHTML={{ __html: checkDateText(item, 2) }}></span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-24 flex-center items-center border border-[#dfdede]">เวลาใดก็ได้</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 3)) {
                        } else {
                          if (checkDateText(item, 3) !== "ว่าง") {
                            setdateSelected(item);
                            setperiodSelected(3);
                            setOrderSelected(getOrderHistorySelected(item, 3));
                          }
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 3) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 3 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 3)) &&
                          <div className="flex-center gap-x-1 py-4 px-2">
                            {checkDateText(item, 3) == "ว่าง" && <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />}
                            <span className="" dangerouslySetInnerHTML={{ __html: checkDateText(item, 3) }}></span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
            {dateSelected &&
              <div className="px-3 sm:px-11 pt-6 pb-9">
                <div className="flex-grow flex justify-between items-baseline px-3 sm:px-9 py-1.5 border-[1px] border-[#797879] border-dashed rounded-full bg-[#e8e8e9]">
                  <div className="flex items-baseline gap-x-3">
                    <div className="">
                      <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="text-xl sm:text-2xl"
                      />
                    </div>
                    <div className="text-xl sm:text-2xl">{ConvertDate(dateSelected, 'ddd, DD MMMM YYYY')}</div>
                  </div>
                  {periodSelected == 1 && <div>
                    <span className="text-xl sm:text-2xl mr-6">ช่วงเช้า</span>
                    <span className="text-xl sm:text-2xl">09.00 - 12.00 น.</span>
                  </div>
                  }
                  {periodSelected == 2 && <div>
                    <span className="text-xl sm:text-2xl mr-6">ช่วงบ่าย</span>
                    <span className="text-xl sm:text-2xl">13:00-17:00 น.</span>
                  </div>
                  }
                  {periodSelected == 3 && <div>
                    <span className="text-xl sm:text-2xl">ช่วงเวลาใดก็ได้</span>
                  </div>
                  }
                </div>
              </div>
            }

          </div>
          {
            orderSelected.items.length > 0 &&
            <div className="bg-white rounded-lg mb-4 mx-2 px-5 sm:px-12 pt-8 pb-12  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
              <div className="flex justify-between items-center mb-3">
                <div className="text-3xl">รายการแจ้งซ่อม</div>
                <div className="text-xl">เลขที่แจ้งซ่อม : {orderSelected.maRef}</div>
              </div>

              <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
                {orderSelected?.items.map((item, index) => {
                  return (
                    <div className="py-3 sm:py-6">
                      <div className="text-2xl sm:text-3xl">รายการที่ {index + 1}</div>
                      <div className="flex gap-x-5">
                        <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg h-fit">
                          {item.catalogName}
                        </div>
                        <div className="text-lg sm:text-xl">
                          {item.subCatalogName}
                        </div>
                      </div>
                    </div>
                  )
                })
                }
              </div>
              <div className="text-3xl mt-8 mb-3">สถานที่</div>
              <div className="flex items-center gap-x-3 sm:gap-x-5">
                {/* <div className="flex-shrink-0 w-[60px] h-[60px] rounded-full border-2 border-gray"></div> */}
                <div>
                  <div className="sm:text-2xl flex gap-x-2">
                    <span>{`${orderSelected.items[0].name}`}</span>
                    <span className="text-gray">Tel : {orderSelected.items[0].phone}</span>
                  </div>
                  <div className="sm:text-2xl">
                    {orderSelected.items[0].addressUser}
                  </div>
                </div>
              </div>

            </div>
          }
          {orderSelected.items.length > 0 &&
            <div className="flex justify-between py-3 px-5 sm:px-12">
              {/* <div className="btn-black-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                ดูรายละเอียดการแจ้งซ่อม
              </div> */}
              <Link to={`/job-detail/${orderSelected.maRef}`}>
                <div className="btn-black-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                  ดูรายละเอียดการแจ้งซ่อม
                </div>
              </Link>
              {
                orderSelected.status == "AF" &&
                // <Link onClick={() => startProject()}>
                <div onClick={() => startProject()} className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto cursor-pointer">
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    className="text-[#999999] bg-black sm:w-[30px] sm:h-[30px] rounded-full"
                  />
                  เริ่มงาน
                </div>
                // </Link>
              }
              {
                orderSelected.status == "FS" &&
                // <Link onClick={() => startProject()}>
                <Link to={`/check-job/${orderSelected.maRef}`}>
                  <div className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-[#999999] bg-black sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                    ตรวจรับงาน
                  </div>
                </Link>
                // </Link>
              }

            </div>
          }

        </form>
      </div >
      {/* <div className="container max-w-3xl mx-auto py-8">
        <div className="bg-white rounded-lg mb-9 mx-2 shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div className="px-5 sm:px-12 pt-6 pb-8">
            <div className="flex justify-between items-center mb-7">
              <div className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                Today
              </div>
              <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                JULY 2022
              </div>
              <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                <div className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                  <FontAwesomeIcon
                    icon={faCircleChevronLeft}
                    className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                  />
                </div>
                <div className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                  <FontAwesomeIcon
                    icon={faCircleChevronRight}
                    className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                  />
                </div>
              </div>
            </div>
            <div className="h-[200px] sm:h-[300px] w-full border-2 border-[#dfdede]"></div>
          </div>
        </div>
        <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div className="flex justify-between px-3 sm:px-12 py-6">
            <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3">
              <div className="text-center w-5">
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="text-xl sm:text-2xl text-gray"
                />
              </div>
              <div className="sm:text-2xl">
                วันอังคาร ที่ 26 กค. 2564 ( 09:00 - 12:00 )
              </div>
            </div>
            <div className="flex justify-center items-center gap-x-3 px-2 sm:px-4 rounded-lg bg-[#fbf2c9] w-[115px] sm:w-[200px]">
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-xl sm:text-2xl text-gray"
              />
              <div className="text-lg sm:text-2xl">รอดำเนินการ</div>
            </div>
          </div>
          <div className="px-5 pt-2 pb-5 sm:px-12">
            <div className="flex justify-between items-baseline">
              <div className="text-2xl sm:text-3xl mb-3 font-semibold">
                รายการแจ้งซ่อม
              </div>
              <div className="text-lg sm:text-xl">
                เลขที่แจ้งซ่อม : AF20220726-1
              </div>
            </div>
            <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
              <div className="py-3 sm:py-6">
                <div className="text-xl sm:text-2xl">รายการที่ 1</div>
                <div className="flex gap-x-5">
                  <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg">
                    ระบบไฟ
                  </div>
                  <div className="text-lg sm:text-xl">หลอดไฟชำรุด</div>
                </div>
              </div>
              <div className="py-3 sm:py-6">
                <div className="text-xl sm:text-2xl">รายการที่ 2</div>
                <div className="flex gap-x-5">
                  <div className="text-lg sm:text-xl px-5 bg-black text-white rounded-lg">
                    เครื่องใช้ไฟฟ้า
                  </div>
                  <div className="text-lg sm:text-xl">ปั้มน้ำชำรุด</div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-6 px-5 sm:px-12">
            <div className="text-xl sm:text-3xl mb-3">สถานที่</div>
            <div className="flex items-center gap-x-3 sm:gap-x-5">
              <div className="flex-shrink-0 w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] rounded-full border-2 border-gray"></div>
              <div>
                <div className="sm:text-2xl">
                  คุณยชญ์ธนัฎฐ์ ธรรมปรีชานันท์{" "}
                  <span className="text-gray">Tel : 0812345678</span>
                </div>
                <div className="sm:text-2xl">
                  643/56 โครงการ โนระ ซอย 3/2, โซน กาจนาภิเษก-ราชพฤกษ์
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between py-3 px-5 sm:px-12">
            <div className="btn-black-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
              ดูรายละเอียดการแจ้งซ่อม
            </div>
            <Link to={`/progress`}>
              <div className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto">
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  className="text-[#999999] bg-black sm:w-[30px] sm:h-[30px] rounded-full"
                />
                เริ่มงาน
              </div>
            </Link>
          </div>
        </div>
      </div> */}
    </div >
  );
};

export default Schedule;
