import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiTransaction extends Component {
    static updateItemByForeman = async (data,maRef) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/UpdateItem/${maRef}`,
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result;
    };
    static createNewTask = async (data,maRef) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/CreateNew/${maRef}`,
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result;
    };
    static updateVerify = async (data,maRef) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/${maRef}`,
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result;
    };
    static update = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman`,
            method: "put",
            data: data
        });
        return result;
    };
    static deleteMain = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/DeleteMain`,
            method: "post",
            data: data,
        });
        return result;
    };
    static deleteItem = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/DeleteItem`,
            method: "post",
            data: data,
        });
        return result;
    };
    static sendRating = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/Rating`,
            method: "post",
            data: data
        });
        return result;
    };
    static sendSignature = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/Signature`,
            method: "post",
            data: data
        });
        return result;
    };
    static getRef = async (ref) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/${ref}`,
            method: "get",
        });
        return result;
    };
    static getRefPostpone = async (ref) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/Postpone/${ref}`,
            method: "get",
        });
        return result;
    };
    static getTransaction = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/Task/${data.status}`,
            method: "get",
        });
        return result;
    };

    static getTaskWeeks = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Foreman/Task/${data.status}`,
            method: "post",
            data: data
        });
        return result;
    };

    static getHistory = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/History`,
            method: "get",
        });
        return result;
    };
}

export default ApiTransaction;
