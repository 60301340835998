import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiAuth from "../../api/ApiAuth";
import ApiUser from "../../api/ApiUser";
import CSwlAlert from "../../utils/alert";
import { setLocalStorage } from "../../utils/localStorage";

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState({
        username: "",
        password: "",
    });

    const navigateToHome = async (e) => {
        CSwlAlert.SwalClose();
        e.preventDefault();
        try {
            const response = await ApiAuth.login(state);
            if (response.status == 200) {
                CSwlAlert.SwalClose();
                // setstate({ ...state, adminLogin: false, userList: [] })
                setLocalStorage("token", response.data.token);
                const responseUser = await ApiUser.getProfile();
                if (responseUser.status == 200) {
                    dispatch({ type: "set", user: responseUser.data.users, token: response.data.token, messageError: "" });
                    navigate('/job');
                }
                // CSwl.SwalClose();
            } else if (response.status == 404) {
                CSwlAlert.SwalErr({ description: "xxx" })
            }
        } catch (error) {
            const { data } = error.response;
            CSwlAlert.SwalErr({ description: data.message })
        }
    }

    return <section className="h-screen">
        <div className="flex flex-col sm:flex-row min-h-full">
            <div className="sm:flex-1 sm:py-0 flex bg-green-light"></div>

            <div className="flex-1 bg-black-200 text-white py-5 sm:py-10 px-8 sm:px-12 flex-col flex justify-center">
                <form onSubmit={navigateToHome}>
                    <>
                        <span className="text-2xl font-semibold">เข้าสู่ระบบ Areeya Foreman</span>
                        <div className="mt-5 flex flex-col">
                            <span className="text-xl required">Username</span>
                            <input value={state.username} onChange={(e) => {
                                setState({ ...state, username: e.target.value })
                            }}
                                className="form-control text-xl" required />
                        </div>
                        <div className="mt-5 flex flex-col">
                            <span className="text-xl required">Password</span>
                            <input value={state.password} onChange={(e) => {
                                setState({ ...state, password: e.target.value })
                            }} type="password" className="form-control text-xl" required />
                        </div>
                        <div className="mt-10">
                            <button type="submit" className="btn-green-square text-black h-12 w-full sm:w-40 text-xl flex items-center justify-center">เข้าสู่ระบบ</button>
                        </div>
                    </>
                </form>
            </div>

        </div>
    </section>
}

export default SignIn;