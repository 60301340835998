import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const initialState = {
    sidebarShow: 'responsive',
    user: {
    },
    token: '',
};

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        case 'clear_all':
            const model = initialState;
            return model;
        default:
            return state;
    }
};

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
    let store = createStoreWithMiddleware(persistedReducer);
    let persistor = persistStore(store);
    return { store, persistor };
};

// const store = createStore(changeState);

// export default store;
