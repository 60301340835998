import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import NavbarMobile from "./NavbarMobile";


const Layout = () => {
    const [sidebar, setSidebar] = useState(false)
    return <>
        <div className={`flex-shrink-0 w-[80%] bg-black ${sidebar ? 'w-[80%]' : 'w-0'} duration-300 sm:hidden`}>
            <SideBar sidebar={sidebar} setSidebar={setSidebar} />
        </div>
        {!sidebar &&
            <div className="App flex flex-col sm:block">
                <>
                    <Header sidebar={sidebar} setSidebar={setSidebar} />
                    <Outlet />
                </>
            </div>
        }
        <Footer />
        <NavbarMobile />
    </>
}

export default Layout;